import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Category(props) {
    const history = useHistory()

    return (
        <div className='main-content'>
            <section id="iq-favorites">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="main-title" style={{ textTransform: "uppercase" }}>{(props?.category?.category_name) || "Loading"}</h6>
                                {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                            </div>
                            <div id="favorites-contens">
                                {/* <div id="prev1" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                        <div id="next1" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                <Swiper
                                    navigation={{
                                        prevEl: '#prev1',
                                        nextEl: '#next1'
                                    }}
                                    breakpoints={{
                                        320: { slidesPerView: 2 },
                                        562: { slidesPerView: 3 },
                                        638: { slidesPerView: 3 },
                                        810: { slidesPerView: 4 },
                                        // 991: { slidesPerView: 4 },
                                        1048: { slidesPerView: 5 },
                                        1300: { slidesPerView: 6 },
                                        1800: { slidesPerView: 7 }
                                        // 1400: { slidesPerView: 4 },
                                        // 1800: { slidesPerView: 5 },
                                    }}
                                    // loop={(movies?.length > 4) ? true : false}
                                    loop={false}
                                    slidesPerView={6}
                                    spaceBetween={0}
                                    as="ul"
                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">
                                    {
                                        props?.category?.video?.length ?

                                            props?.category?.video?.map((data, index) => (
                                                <SwiperSlide as="li" key={index}>
                                                    <div style={{cursor:"pointer"}} onClick={()=>{
                                                      
                                                        if(data?.stream_category_name=="NEWS CHANNELS")
                                                        history.push(ROUTES.stream, { stream: {data:data} })
                                                    else
                                                    history.push(`/live-streaming-details/${data?.slug}`)

                                                    }}>
                                                        <img src={`${IMAGE_ENDPOINT}/${data?.thumbnail}`} className='thumb-img' alt={data?.category_name} />
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                            :
                                            <>Data not found</>
                                    }

                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
