import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as HomeAction from '../../../actions/HomeActions'
import * as MovieAction from '../../../actions/MovieAction'

import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap'
import Category from '../home/Category'
import { Autocomplete, Paper, TextField } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from '../../../constant/routes'

export default function Locality() {

    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const [category, setCategory] = useState([])
    const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const { getAreaByCity } = MovieAction
    const [area, setArea] = useState([])
    const history = useHistory()
    useEffect(() => {
        (async () => {
            let userObj = {
                user_id: user_id ? user_id : "null",
                profile_id: profile_id ? profile_id : "null"
            }

            const resData = await getAllHomeData(userObj)
            const areas = await getAreaByCity()
            setArea(areas?.data)
            console.log(areas)
            setCategory(resData?.data)
        })()
    }, [])

    const handleRedirect = (event, value) => {
        console.log(event, value)
        history.push(`${ROUTES.videoByArea + "/" + value?.slug}/${value?.pincode}`)

    }
    return (
        <section className="m-profile setting-wrapper mb-5 mt-5">
            <Container>
                <div className='mt-1 mb-5 responsive-search' style={{ width: "70%", margin: "auto" }}>
                    {/* <Row>
                        
                        <Col md="4" className='mt-2 mb-3'> */}
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={area}
                        getOptionLabel={(option) => option?.locality_name + " - " + option?.pincode}
                        onChange={(event, value) => handleRedirect(event, value)}
                        sx={{

                            borderRadius: "1rem",

                        }}
                        PaperComponent={({ children }) => (
                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                        )}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Enter your area name or pincode"
                                inputProps={{
                                    ...params.inputProps,
                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    style: { color: 'white' }, // Change label color
                                }}
                            />}
                    />
                    {/* </Col>
                    </Row> */}
                </div>

            </Container>


            {/* {
                category?.map((data, index) => (
                    <div key={index}>
                        <Category category={data} />

                    </div>
                ))
            } */}

        </section>
    )
}
