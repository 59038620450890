import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useMemo } from "react";
import Grid from "@mui/material/Grid";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const {
	
		order,
		orderBy,
		onRequestSort,
		headCells,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead
			sx={{
				background:
					"linear-gradient(to right, #c22034 , #07177c)",
			}}
		>
			<TableRow>
			
				{headCells.map((headCell) => (
					<TableCell
						sx={{
							borderBottom: "1px solid rgb(97 97 97)",
							fontSize: "13.5px",
							padding: headCell?.padding || "10px 8px",
							fontWeight: 600,
						}}
						key={headCell.id}
						align={(headCell?.align || "left")}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{(headCell.isImage || headCell.id == "edit") ? <p style={{ color: "#fff" }}>{headCell.label}</p> :
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								<p style={{ color: "#fff" }}>{headCell.label}</p>
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	headCells: PropTypes.array.isRequired,
	
};

function EnhancedTableToolbar(props) {
	

	const {
		
		title,
	
		setSearch,
		search,
		
	} = props;

	return (
		<Toolbar
			sx={{
				// pl: { sm: 2 },
				// pr: { xs: 1, sm: 1 },

			}}
			className="ss-table-toolbar"
		>
			
				<Typography
					// sx={{ flex: "1 1 40%" }}
					style={{ width: "max-content", whiteSpace: "nowrap" }}
					variant="p"
					color="black"
					// color="white"

					fontSize={20}
					id="tableTitle"
					component="div"
					fontWeight={600}
				>
					<span style={{color:"white"}}>{title}</span>
					
				</Typography>
			

			
				<Grid
					container
					justifyContent="flex-end"
					alignItems={"center"}
					rowSpacing={1}
					columnSpacing={{ xs: 1, sm: 2, md: 2 }}
				>
				
								

					<Grid
						item
						xs={10}
						sm={4}
						md={3}
						lg={ 3}
						key={"Search"}
					>
						<input
							id="search-bar"
							className="text"
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							label="Search"
							variant="outlined"
							placeholder="Search..."
							size="small"
                            sx={{ color: 'white' }}
							value={search}
						/>
					</Grid>
					

				</Grid>
		
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	
	title: PropTypes.string.isRequired,
	column_sum: PropTypes.string,
	setSearch: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	
};

export default function ListTable({

	tableData,
	
}) {
	
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	



	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - tableData.tableBody.length)
			: 0;

	
	



	//Search
	const [search, setSearch] = useState("");

	const [results, setResults] = useState([...tableData.tableBody]);
	useMemo(() => {
		
		setResults([...tableData.tableBody]);
	}, [tableData.tableBody]);
	useMemo(() => {
		
		
	

		const temp = tableData.tableBody.filter((item) => {
			let found = false;
			Object.entries(item).map(([key, value]) => {
				if (String(value).toLowerCase().includes(search.toLowerCase())) {
					found = true;
				}
			});
			return found;
		});

		setResults([...temp]);
	}, [search]);
	
	
	

console.log(results)

	return (
		<>
			
			<Card
				sx={{
					boxShadow: "none",
					borderRadius: "10px",
					// p: "25px",
					// mb: "15px",
                    backgroundColor:"transparent"
				}}
			>
				<Box sx={{ width: "100%" }}>
					<EnhancedTableToolbar
						
						title={tableData.tableTitle + " : " + results?.length}
						
						setSearch={setSearch}
						search={search}

					/>
					<TableContainer
						component={Paper}
						sx={{
							boxShadow: "none",
                            backgroundColor:"transparent"
						}}
					>
						<Table
							aria-labelledby="tableTitle"
							className="dark-table sorting-selecting-table"
						>
							<EnhancedTableHead
								
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={results?.length}
								headCells={tableData.tableHead}
								disableDelete={!tableData.disableDelete}
							/>
							{results.length != 0 ?
								<TableBody >
									
									{stableSort(results || [], getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => 
											
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={index + "TableRow"}
													
												>
											
													
													{tableData.tableHead.map((value, index) => 
														
                                               
													
																<TableCell
																	sx={{
																		borderBottom: "1px solid  rgb(97 97 97)",
																		padding:"8px 8px",
																		fontSize: "13px",
																		
																	}}
																	align={"left"}
																	key={index}
																	
																>
																	<p style={{ color: "white",marginBottom:"0" }}>
																		{ row[value.id]}
																	</p>
																	{row[value?.subText] && <span style={{color:"white"}}>({row[value?.subText]})</span>}
																</TableCell>
													
                                                      
													)}
												</TableRow>
											)
										}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: ( 53) * emptyRows,
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
								:
								<TableBody ><TableRow >
									<TableCell colSpan={"12"} >
										<div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center", marginTop: "2rem"  }}>	
                                        
                                        {/* <img src={empty} alt="empty" /> */}

											<p style={{ color: "white", textAlign: "center", }}>No data found . </p>
											
										</div>
									</TableCell>
								</TableRow>
								</TableBody>}

						</Table>
					</TableContainer>

					<TablePagination
						rowsPerPageOptions={5}
						component="div"
						count={results?.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						className="ss-table-table-pagination"
                        sx={{color:"white"}}
					/>
				</Box>
			</Card></>
	);
};
