import React, { useEffect, useState } from 'react'
import Slider from './Slider'
// import MovieBanner from './MovieBanner'
import { bindActionCreators } from 'redux'
import { useDispatch,useSelector } from 'react-redux'
import {live_streaming_dashboard} from '../../../actions/HomeActions'
import Category from './Category'



export default function Home() {
    const user=JSON.parse(sessionStorage.getItem('user'))
    const profile=JSON.parse(sessionStorage.getItem('profile'))
  const dispatch = useDispatch()
 
  useEffect(() => {
   
    dispatch(live_streaming_dashboard({profile_id:profile?.id||"null",
        user_id:user?.id||"null"}))
        document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [])
const home = useSelector((state)=>state.home.liveStreamData?.data)

  return (
    <>
      <Slider Sliderdata={home?.Banner} />
      {home?.Category?.data?.map((ele,index)=><div key={index}><Category category={ele} /></div>)}
      {/* <MovieBanner promotion={home?.Promotion} /> */}
      
    </>
  )
}
