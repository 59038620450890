import React,{useState, useEffect, useMemo}  from 'react'

import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import {refund_policy} from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
//img
import aboutus1 from '../../../../assets/images/Bg.jpg'

const RefundPolicy = () => {
    const dispatch=useDispatch()
    useEffect(()=>{dispatch(refund_policy())},[])
    const data = useSelector((state) => state.home.refund_policy);
    return (
        <>
           
            <main id="main" className="site-main">
                <Container>

                <h4 className="title" style={{margin:"2rem 0"}}>Refund Policy</h4>

                    <Row>
                        <Col lg="12" sm="12">
                            <div className="iq-privacy-policy">
                                <div dangerouslySetInnerHTML={{__html:data?.data?.[0]?.description}}></div>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    )
}

export default RefundPolicy;