import { createStore, combineReducers, applyMiddleware } from 'redux'
import Mode from './mode/rtlmode'
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import  HomeStore  from './mode/HomeStore';
import PaymentStore from './mode/PaymentStore';
import MovieStore from './mode/MovieStore';
import SeriesStore from './mode/SeriesStore';

const initialState = {};
const middlewares = [thunk];

export default createStore(
    combineReducers({
        mode: Mode,
        home:HomeStore,
        payment:PaymentStore,
        movies:MovieStore,
        series:SeriesStore
    }),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))

)
