import React from 'react'
import AddMovie from './add-movie'

export default function MovieDetailsPage() {
  return (
    <>
        <AddMovie/>
    </>
  )
}
