import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Breadcrumb, Form, Button } from 'react-bootstrap'

//img
import watchlistBG from '../../../assets/regal_images/promotion_bk_1.png'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import { Alert } from '@mui/material';
import { ROUTES } from '../../../constant/routes';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertMessage from '../common/AlertMessage';
import { toast } from 'react-toastify';
import empty from '../../../assets/regal_images/empty-box (1).png'
const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];

const ITEM_HEIGHT = 48;


export default function WatchList() {
    const dispatch = useDispatch()
    const { getUserWatchlist } = bindActionCreators(MovieAction, dispatch)
    const [movie, setMovie] = useState([])
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const history = useHistory()
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const [save, setSave] = useState(false)
    const { removeFromWatchlist } = MovieAction
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [movieId, setMovieId] = useState("")
    const [seriesId, setSeriesId] = useState("")


    useEffect(() => {
        (async () => {
            // document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (profile_id && user_id) {
                let userObj = {
                    "profile_ID": profile_id,
                    "user_id": user_id
                }
                const resData = await getUserWatchlist(userObj)
                setMovie(resData?.data)
            }
            else {
                history.push('/login')
            }

        })()
    }, [save])


    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setMovieId(id)
    };

    const handleSeriesId = (event, id) => {
        setAnchorEl(event.currentTarget);

        setSeriesId(id)
    }

    const handleClose = async () => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_id: profile_id,
                movie_id: parseInt(movieId) ? parseInt(movieId) : "",
                series_id: parseInt(seriesId) ? parseInt(seriesId) : ""
            }
            const res = await removeFromWatchlist(userObj)
            if (res?.statuscode == 200) {
                setMovieId("")
                setSeriesId("")
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            history.push('/login')
        }
        setAnchorEl(null);
    };

    const redirecttoSeries = (data) => {
        // history.push(ROUTES.seriesDetails, { series: data?.series?.id })
        history.push(`${ROUTES.seriesDetails}/${data?.series?.slug}`)

    }
    return (
        <>
            {/* <AlertMessage /> */}
            <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${watchlistBG})` }}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col sm="12">
                            <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                <h2 className="title">Watch Later</h2>
                                <Breadcrumb className="main-bg">
                                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Watch Later</Breadcrumb.Item>
                                </Breadcrumb>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                movie?.length ?
                    <div>
                        <main id="main" className="main-content">
                            <Container>
                                <h4 className="">Movies & Series</h4>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        {
                                            movie?.length ?
                                                movie?.map((data, index) => (
                                                    data?.movie ?
                                                        <Grid item lg={2} xl={2} md={2} sm={4} xs={6} key={index}>
                                                            <div className='movie-data'>
                                                                <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`}>
                                                                    <img src={`${IMAGE_ENDPOINT + data?.movie?.movie_thumbnail}`} className="thumb-img-filter mt-4" alt={data?.movie?.movie_name} />
                                                                </Link>
                                                                <div className='menu-dots'>
                                                                    {/* <i className="fa fa-ellipsis-v" aria-hidden="true"></i> */}
                                                                    <IconButton
                                                                        aria-label="more"
                                                                        id="long-button"
                                                                        aria-controls={open ? 'long-menu' : undefined}
                                                                        aria-expanded={open ? 'true' : undefined}
                                                                        aria-haspopup="true"
                                                                        onClick={(event) => handleClick(event, data?.movie?.id)}
                                                                    >
                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true" style={{ color: "white" }} ></i>

                                                                    </IconButton>
                                                                    <Menu
                                                                        id="long-menu"
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'long-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open}
                                                                    >
                                                                        <MenuItem onClick={handleClose}>
                                                                            <DeleteIcon /> Delete
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </div>

                                                            </div>

                                                        </Grid>
                                                        :
                                                        <></>

                                                ))
                                                :
                                                <></>
                                        }
                                    </Grid>
                                </Box>
                            </Container>
                        </main>
                        <main id="main" className="main-content">
                            <Container>
                                {/* <h4 className="">Web Series</h4> */}
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        {
                                            movie?.map((data, index) => (
                                                data?.series ?
                                                    <Grid item lg={4} xl={4} md={6} sm={6} xs={12} key={index}>
                                                        <div className='movie-data'>
                                                            <img src={`${IMAGE_ENDPOINT + data?.series?.series_poster}`} className="series-img mt-4" alt={data?.series_name} onClick={() => redirecttoSeries(data)} />
                                                            <div className='series-dots'>
                                                                {/* <i className="fa fa-ellipsis-v" aria-hidden="true"></i> */}
                                                                <IconButton
                                                                    aria-label="more"
                                                                    id="long-button"
                                                                    aria-controls={open ? 'long-menu' : undefined}
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    aria-haspopup="true"
                                                                    onClick={(event) => handleSeriesId(event, data?.series?.id)}
                                                                >
                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true" style={{ color: "white" }} ></i>

                                                                </IconButton>
                                                                <Menu
                                                                    id="long-menu"
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'long-button',
                                                                    }}
                                                                    anchorEl={anchorEl}
                                                                    open={open}
                                                                >
                                                                    <MenuItem onClick={handleClose}>
                                                                        <DeleteIcon /> Delete
                                                                    </MenuItem>
                                                                </Menu>
                                                            </div>

                                                        </div>

                                                    </Grid>
                                                    :
                                                    <></>
                                            ))

                                        }
                                    </Grid>
                                </Box>
                            </Container>
                        </main>
                    </div>

                    :
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='mt-5'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={empty} alt='empty' style={{ height: "60px" }} />
                            <p className='pt-4'>Sorry, You have not added any movies or series yet !</p>
                        </div>
                    </Grid>
            }


        </>
    )
}
