import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import VideoJS from '../../../player/player'
import videojs from 'video.js';
import * as ads from 'videojs-contrib-ads'
//img
import episode1 from '../../../assets/images/episodes/01.jpg'
import episode2 from '../../../assets/images/episodes/02.jpg'
import episode3 from '../../../assets/images/episodes/03.jpg'
import episode4 from '../../../assets/images/episodes/04.jpg'
import episode5 from '../../../assets/images/episodes/05.jpg'
import episode6 from '../../../assets/images/episodes/06.jpg'
import episode7 from '../../../assets/images/episodes/07.jpg'
import episode8 from '../../../assets/images/episodes/08.jpg'
import episode9 from '../../../assets/images/episodes/09.jpg'
import episode10 from '../../../assets/images/episodes/10.jpg'
// import video from '../../../assets/video/sample-video.mp4'


// require("@silvermine/videojs-quality-selector")(videojs);
const ShowList = () => {
   const playerRef = React.useRef(null);

   const videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
   
      //  playbackRates: [0.5, 1, 1.5, 2],
      userActions: { hotkeys: true },
      controlBar: {
         qualitySelector: true,
         skipButtons: {
            forward: 10,
            backward:10
          },
         pictureInPictureToggle: false,

      },


      languages: [{}],
      sources: [

         {
            src: "http://46.250.229.206/mp4/ANIMAL_TEASER.mp4",
            type: 'video/mp4',
            label: "360p",
            selected: true
         }, 
         // {
         //    src: video,
         //    type: 'video/mp4',
         //    label: "480p",

         // }
         ,
         {
            src: "https://vjs.zencdn.net/v/oceans.mp4?HD",
            type: 'video/mp4',
            label: "720p"
         }
      ],
      tracks: [{ src: 'https://domain/mysub.vtt', kind: 'captions', srclang: 'en', label: 'English' }]
   };
   const [adsFlag, setAdsFlag] = useState([true])

   const handlePlayerReady = (player) => {
      playerRef.current = player;
      player.addChild("QualitySelector")
      //  player.addChild("PlaybackRateMenuButton")
      player.addChild("fullscreenToggle")
      player.controlBar.removeChild("QualitySelector")
      //  player.controlBar.removeChild("PlaybackRateMenuButton")
      player.controlBar.removeChild("fullscreenToggle")
      player.titleBar.removeClass('vjs-hidden')
      player.titleBar.addChild('button', {
         controlText: 'Title',
         className: 'vjs-visible-text',
         position: 2,
      });

      player.ads();
      player.on('timeupdate', function () {
         // in a real plugin, you might fetch new ad inventory here
         if (Math.round(player?.currentTime()) == "40" && adsFlag[0]) {
            player.trigger('adsready');
            player.controlBar.progressControl.disable()
            player.controlBar.seekForward.disable()
            player.controlBar.seekBack.disable()

         }
         console.log(player?.currentTime())
      });

      player.on('readyforpreroll', function () {
         player.ads.startLinearAdMode();
         // play your linear ad content
         // in this example, we use a static mp4
         player.src("https://vjs.zencdn.net/v/oceans.mp4?HD");
         player.options.controls = false;
         // send event when ad is playing to remove loading spinner
         player.on('adplaying', function () {
            player.trigger('ads-ad-started');
         });

         // resume content when all your linear ads have finished
         player.on('adended', function () {
            player.ads.endLinearAdMode();
            const temp = adsFlag
            temp[0] = false
            setAdsFlag([...temp])
            player.controlBar.progressControl.enable()
            player.controlBar.seekForward.enable()
            player.controlBar.seekBack.enable()
         });
      });
      // You can handle player events here, for example:
      player.on('waiting', () => {
         videojs.log('player is waiting');
      });

      player.on('dispose', () => {
         videojs.log('player will dispose');
      });
   };
   return (
      <>
        
         <div>
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
         </div>
         
      </>
   )
}
export default ShowList; 