import { GET_EPISODE_DETAILS, GET_EPISODE_DETAILS_ERROR, GET_SERIES_DETAILS, GET_SERIES_DETAILS_ERROR } from "../../Actiontype/ActionType"

const initstate = { seriesDetails: null, episodeData: null }

const SeriesStore = (state = initstate, action) => {

    const { type, payload } = action

    switch (type) {

        case GET_SERIES_DETAILS: {
            return {
                ...state,
                seriesDetails: payload
            }
        }
        case GET_SERIES_DETAILS_ERROR: {
            return {
                ...state,
                seriesDetails: null
            }
        }
        case GET_EPISODE_DETAILS: {
            return {
                ...state,
                episodeData: payload
            }
        }
        case GET_EPISODE_DETAILS_ERROR: {
            return {
                ...state,
                episodeData: null
            }
        }

        default:
            return state
    }

}


export default SeriesStore