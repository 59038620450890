import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
// Partials
import HeaderStyle1 from '../../components/partials/backend/headerstyle/headerstyle1';
import FooterStyle from '../../components/partials/backend/footerstyle/footerstyle'
import $ from 'jquery';

// Router Component
import Layout1Route from '../../router/layout1-route'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Layout1 = () => {
var scrollDirection=0
const location=useLocation()

  const backToTop = document.getElementById("back-to-top")
  // console.log(backToTop)
  if (backToTop !== null && backToTop !== undefined) {
    document.getElementById("back-to-top")?.classList?.add('animated', 'fadeOut')
    window.addEventListener('scroll', (e) => {
      if (document.documentElement.scrollTop > 50) {
        document.getElementById("back-to-top")?.classList?.remove("fadeOut")
        document.getElementById("back-to-top")?.classList?.add("fadeIn")
      } else {
        document.getElementById("back-to-top")?.classList?.remove("fadeIn")
        document.getElementById("back-to-top")?.classList?.add("fadeOut")
      }
    })
    // scroll body to 0px on click
    document.querySelector('#top').addEventListener('click', (e) => {
      e.preventDefault()
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  }

  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $('.main-header').addClass('main-header-custom');
    } else {
      $('.main-header').removeClass('main-header-custom');
    }
  });
useEffect(()=>{
  document.getElementById("main-div").scrollTo({ top: 0, behavior: 'smooth' });
 
},[location.pathname,location?.state?.series])
  function muteMe(elem) {
    elem.muted = true;
    const mute_button = document.getElementById("mute-button")
    if (mute_button)
      mute_button.className = "ri-volume-mute-fill"
  }
    function unMuteMe(elem) {
    elem.muted = false;
    const mute_button = document.getElementById("mute-button")
    if (mute_button)
      mute_button.className = "ri-volume-up-fill"
  }
  const handleScrollMuteVideo = () => {
  
    if ($(document.getElementById("main-div")).scrollTop() > (document.getElementsByClassName("bg-video")[0])?.scrollHeight&&$(document.getElementById("main-div")).scrollTop()>scrollDirection) {
      document.querySelectorAll("video, audio").forEach((elem) => muteMe(elem));
  
        }
         if ($(document.getElementById("main-div")).scrollTop() < (document.getElementsByClassName("bg-video")[0])?.scrollHeight&&$(document.getElementById("main-div")).scrollTop()<scrollDirection) {
      document.querySelectorAll("video, audio").forEach((elem) => unMuteMe(elem));
  
        }
      if ($(document.getElementById("main-div")).scrollTop() > 50) {

        $('.main-header').addClass('main-header-custom');
      } else {
        $('.main-header').removeClass('main-header-custom');
      }
 scrollDirection=$(document.getElementById("main-div")).scrollTop()
  }

  return (
    <div
    id='main-div'
      onScroll={handleScrollMuteVideo}
      style={{ height: "100vh", overflowY: "auto" }}
    >

      <div id="back-to-top" style={{opacity:"0"}}>
        <Link className="top" to="#" id="top"> <i className="fa fa-angle-up"></i> </Link>
      </div>
      <div className="wraper">
        <HeaderStyle1 />
        <div className="content-page" id="content-page">
          <Layout1Route />
        </div>
      </div>
      <FooterStyle />
    </div>
  )
  
}


export default Layout1;