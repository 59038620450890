import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as HomeActions from "../../../actions/HomeActions";
import { IMAGE_ENDPOINT, LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Share from '../common/Share';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'
import AlertMessage from '../common/AlertMessage';
import { toast } from 'react-toastify';
import plus from '../../../assets/regal_images/plus.png'
import check from '../../../assets/regal_images/check.png'
import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative, Thumbs } from "swiper";
import { Box, Tooltip, styled } from '@mui/material';
import VideoJS from '../../../player/player'
import videojs from 'video.js';
import animatedGIF from '../../../assets/regal_images/Animation.gif'
import { EffectCoverflow } from 'swiper';
import axios from 'axios';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};
const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

function Slider() {
    const playerRef = React.useRef(null);

    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [slug, setSlug] = useState("")
    const [watchListopen, setWatchlistOpen] = React.useState(false);
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const [save, setSave] = useState(false)
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { getMovieDetails, addtoRedirectCount } = bindActionCreators(HomeAction, dispatch)
    const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

    const { setCompleteHours } = MovieAction

    const { getSliderData } = bindActionCreators(HomeAction, dispatch)
    const [popupOpen, setPopupOpen] = useState(false)
    const [selectedMovie, setSelectedMovie] = useState()
    const { removeFromWatchlist } = MovieAction
    const history = useHistory()
    const [sliderData, setSliderData] = useState([])
    const [count, setCount] = useState(-2);
    SwiperCore.use([Autoplay, EffectCoverflow, Thumbs])
    const [animationStart, setAnimationStart] = useState(false)
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [tempData, setTempData] = useState("")
    let maxMovieWatchFlag = false
    useEffect(() => {
        (async () => {

            const sliderRes = await getSliderData()
            if (sliderRes?.statuscode == 200) {

                setSliderData(sliderRes?.data)

            }

        })();
    }, [save])

    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }



    const handleRedirect = (data) => {
        // if (isLoggedIn) {
            console.log(data)
            let userObj = {
                movie_name: data.slider_name,
                Trailer: data.slider_video_link
            }
    
            history.push(ROUTES.trailer, { data: userObj })
            // history.push(ROUTES.video, { video: data })

        // }
        // else {
        //     toast.error("Please login to continue.")
        // }
    }
    const handlePopupClose = () => {
        setPopupOpen(false);
    };





    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#ffffff',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const googlemap = async (link) => {
        console.log(link)
        if (!isLoggedIn) {
            toast.error("Please login to continue.")
        }
        else {
            const resData = await addtoRedirectCount({ user: user_id, video: link?.id })
            console.log("data", resData)
            if (resData?.statuscode == 200) {
                window.open(link?.video_link_direction, '_blank');
            }

        }
    }


    return (
        <>
            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {!isLoggedIn ? <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Please login to continue.
                        </Typography>
                        <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box> :
                    (selectedMovie?.max_movie_watch_complete == "true" ?
                        <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    You have already seen this film maximum number of times. To watch it again, purchase a Diamond Plan.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>

                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Box> : <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "left", fontSize: "19px", color: "red", fontWeight: "900" }}>
                                {/* <img src={logo} width={"100px"} /> */}
                                Maturity Rating: 18+
                            </div>
                            <hr />


                        </Box>)
                }
            </Modal>


            <section id="home" className="iq-main-slider p-0 iq-rtl-direction slider-cls">
                {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    navigation={{
                        prevEl: '#prev5',
                        nextEl: '#next5'
                    }}

                    pagination={{
                        "clickable": true
                    }}

                    autoplay={{
                        delay: 4000,
                    }}
                    onInit={() => { animationInit() }}
                    onSlideChangeTransitionStart={() => animationInit()}
                    loop={true}
                    id="home-slider"
                    className="slider m-0 p-0">
                    {
                        sliderData?.map((data, index) => (
                            <SwiperSlide
                                className="slide slick-bg"
                                key={index}
                                style={{ backgroundImage: `url(${LOCALITY_IMAGE_ENDPOINT + (data?.web_banner)})` }}
                            >
                                <Container fluid className="position-relative h-100">
                                    <div className="slider-inner h-100">
                                        <Row className="align-items-center  iq-ltr-direction h-100 ">
                                            <Col xl="6" lg="12" md="12">
                                                <div style={{ cursor: "pointer" }}>
                                                    <span className='slider-font'>{data?.slider_name}</span>
                                                </div>
                                                {/* <Link to={`/live-streaming-details/${data?.stream?.slug}`}>
                                                    <h2 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{data?.stream?.movie_name}</h2>
                                                </Link> */}
                                                {/* <div className="">

                                                    <div style={{ cursor: "pointer" }}>
                                                        <div className="d-flex flex-wrap align-items-center pt-4">
                                                            <p>
                                                                {data?.video?.video_description}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                        <p>
                                                            <span>{data?.video?.video_category}</span>
                                                        </p>

                                                    </div>

                                                </div> */}

                                                <div className="d-flex align-items-start r-mb-23" style={{ marginTop: "1rem" }}>
                                                    <button className="btn btn-hover iq-button"
                                                        onClick={() => handleRedirect(data)}
                                                    //    onClick={()=>history.push(ROUTES.video, { video: data?.video })}


                                                    >
                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>Play
                                                    </button>

                                                    {/* <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`} className="btn btn-hover iq-button ">
                                                            <i class="fa fa-info" style={{ padding: "2px 3px", fontSize: "12px", paddingLeft: "5px", border: "3px solid white", borderRadius: "50%", marginRight: ".5rem" }}></i>More Info
                                                        </Link> */}
                                                    {/* <i className="fa fa-share icon-border ml-2"></i> */}
                                                    {/* <div className='icon-border-details ml-2' style={{ cursor: "pointer" }} onClick={() => googlemap(data?.video)}>
                                                        <i className="fa fa-location-arrow details-con" aria-hidden="true"></i>  Directions

                                                    </div> */}




                                                    {/* <div onClick={(event) => handleClick(event, data?.movie?.slug)}>
                                                        <i className="fa fa-share-alt share-icon ml-3" aria-hidden="true"></i>
                                                    </div> */}



                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>
                                                            <Share sharedURL={`${window.location.href}movie-details/${slug}`} />
                                                        </Typography>
                                                    </Popover>

                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </Container>
                            </SwiperSlide>
                        ))
                    }


                </Swiper>
            </section>

        </>

    )
}
export default Slider