import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Nav, Card } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Navigation, Pagination, Autoplay, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';
import Tab from 'react-bootstrap/Tab';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as HomeActions from "../../../actions/HomeActions";
import * as MovieAction from "../../../actions/MovieAction";
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_ENDPOINT, LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { Avatar, Box, CircularProgress, Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import Share from '../common/Share';
// import videotest from '../../../assets/video/sample-video.mp4'
import VideoJS from '../../../player/player'
import videojs from 'video.js';
// import * as videoButtons from 'videojs-seek-buttons'
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// require("@silvermine/videojs-quality-selector")(videojs);
import trending1 from '../../../assets/images/trending/01.jpg'
import plus from '../../../assets/regal_images/plus.png'
import check from '../../../assets/regal_images/check.png'
import { toast } from 'react-toastify';
import AlertMessage from '../common/AlertMessage';
import RazorpayButton from '../../../razorpay/razorpayButton'
import playIcon from '../../../assets/regal_images/play-button.png'
import Modal from '@mui/material/Modal';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import axios from 'axios';
import unmute from '../../../assets/regal_images/volume.png'
import silent from '../../../assets/regal_images/mute.png'
import Helmet from 'react-helmet';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

// install Swiper modules
// SwiperCore.use([Navigation]);


const AddMovie = () => {
    const playerRef = React.useRef(null);
    const [toggler1, setToggler1] = useState(false);
    const parameter = useParams();
    const [movieData, setMovieData] = useState("")
    const dispatch = useDispatch()
    const { getMovieDetails, addtoRedirectCount } = bindActionCreators(HomeActions, dispatch)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [save, setSave] = useState(false)
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [popupOpen, setPopupOpen] = useState(false)
    const handlePopupClose = () => {
        setPopupOpen(false);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    SwiperCore.use([Autoplay, EffectCoverflow, Thumbs])

    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { addToLikedContent } = MovieAction
    const { removeFromWatchlist, setCompleteHours } = MovieAction
    const [ip, setIP] = useState("")
    const movie_data = useSelector((state) => state?.home?.movieDetails)
    console.log("movie_data", movie_data)
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip)
        console.log(res)

    };
    useEffect(() => {
        (async () => {
            getData();
        })();
    }, []);


    useEffect(() => {
        (async () => {

            document.body.scrollTop = document.documentElement.scrollTop = 0;

            if (parameter?.slug) {
                const res = await axios.get("https://api.ipify.org/?format=json");

                const resData = await getMovieDetails(parameter?.slug)
                setMovieData(resData)
                console.log(resData)
            }

            const intervalId = setInterval(() => {
                setHoursLeft(calculateHoursLeft());
            }, 1000);

            // Clear the interval when the component is unmounted
            return () => clearInterval(intervalId);

        })();
    }, [save, parameter?.slug])
    // useEffect(() => {
    //     const getLocation = () => {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(
    //                 (position) => {
    //                     setLatitude(position.coords.latitude);
    //                     setLongitude(position.coords.longitude);
    //                 },
    //                 (error) => {
    //                     setError(error.message);
    //                 }
    //             );
    //         } else {
    //             setError('Geolocation is not supported by this browser.');
    //         }
    //     };

    //     getLocation();
    // }, []);
    // console.log(latitude , longitude)
    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }



    const handlePlayNow = () => {
        if (!isLoggedIn) {
            toast.error("Please login to continue")
        }
        else {
            history.push(ROUTES.video, { video: movieData.Data })
        }


    }

    const handleHoursContinue = async () => {
        const send_status = await setCompleteHours({
            user: user_id,
            movie: movieData?.Data?.id
        })
        if (send_status?.statuscode == 200) {
            history.push(ROUTES.video, { movie: movieData, resume: movieData?.last_watching_time })
        }

    }





    const handleHoursDialogueClose = () => {
        setHoursAlert(false);
        setCompletedHour(false)
    };



    const googlemap = async () => {
        if (!isLoggedIn) {
            toast.error("Please login to continue.")
        }
        else {
            // const resData = await addtoRedirectCount({ user: user_id, video: movieData?.Data?.id })
            // console.log("data", resData)
            // if (resData?.statuscode == 200) {
            window.open(movieData?.Data?.video_link_direction, '_blank');

            // }
        }
    }

    const handleRedirect = (data) => {
        if (isLoggedIn) {
            let userObj = {
                movie_name: movieData?.Data?.video_name,
                Trailer: movieData?.Data?.video_link_mp4,
                video: movieData?.Data?.id
            }

            history.push(ROUTES.trailer, { data: userObj })
        }
        else {
            toast.error("Please login to continue")
        }

    }
    console.log("movieData?.Data?.poster_images?.[0]", movieData?.Data?.poster_images?.[0])
    let dict = {
        image: movieData?.Data?.poster_images?.[0]?.replace("/OTT/static/image/", ""),

    }
    let shareUrl = window.location.href 
    console.log("shareUrl", shareUrl)
    return (
        <>
            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {!isLoggedIn ? <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Please login to continue.
                        </Typography>
                        <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box> :
                    (movieData?.max_movie_watch_complete == "true" ?
                        <Box sx={styleforAlert}>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={logo} width={"100px"} />
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                    You have already seen this film maximum number of times. To watch it again, purchase a Diamond Plan.
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "center" }}>

                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </Box> : <Box sx={style}>

                            <div style={{ display: "flex", justifyContent: "left", fontSize: "19px", color: "red", fontWeight: "900" }}>
                                {/* <img src={logo} width={"100px"} /> */}
                                Maturity Rating: 18+
                            </div>
                            <hr />

                            <div className="login-popup" >
                                <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "center" }}>
                                    May contain mature content, nudity, violence, foul language, substances.
                                </Typography>
                                <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                                    I CONFIRM THAT I AM 18 YEARS AND ABOVE
                                </Typography>
                                <div className='mt-3' style={{ display: "flex", justifyContent: "end" }}>
                                    <button onClick={handlePlayNow} className={"btn btn-hover iq-button"}>
                                        OK
                                    </button>
                                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button ml-2"} >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Box>)
                }
            </Modal>
            {
                movie_data && movieData ?
                    <div>
                        <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                            <Swiper
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                loop={true}
                                slidesPerView={1.17}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 150,
                                    depth: 200,
                                    modifier: 1.8,
                                }}

                                initialSlide={1}
                                pagination={{ el: '.swiper-pagination', clickable: true }}
                                navigation={{
                                    nextEl: 'swiper-buton-next',
                                    prevEl: 'swiper-button-pre',
                                    clickable: true
                                }}
                                modules={[EffectCoverflow, Pagination, Navigation]}
                                className='swiper-container '

                                autoplay={{
                                    delay: 3500,
                                }}
                                style={{ marginTop: "70px", marginBottom: "1rem" }}
                            >
                                {
                                    movieData?.Data?.poster_images?.map((data, index) => (
                                        <SwiperSlide
                                            // className="slide slick-bg"
                                            key={index}
                                            style={{ backgroundSize: "cover" }}
                                        >
                                            <img className='home-slider-res' src={IMAGE_ENDPOINT + '/' + data} alt="ddd" />

                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>
                            <Container style={{ marginTop: "2rem" }}>

                                <Row style={{ border: "1px solid grey", padding: "10px", width: "95%", margin: "auto" }}>
                                    <Col md="12" style={{ display: "flex", justifyContent: "end", fontSize: "20px", fontWeight: "900" }}>
                                        <div onClick={handleClick} style={{ border: "1px solid #ffffff", padding: "10px", borderRadius: "0.5rem" }}>
                                            <i className="fa fa-share-alt" aria-hidden="true"></i>
                                        </div>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }}>
                                                <Share sharedURL={shareUrl} poster={movieData?.Data?.poster_images?.[0]} />
                                            </Typography>
                                        </Popover>
                                    </Col>
                                    <Col md="12" style={{ display: "flex", justifyContent: "center", fontSize: "20px", fontWeight: "900" }}>
                                        Resort Details
                                    </Col>
                                    <Col md="2">
                                        Resort Name
                                    </Col>
                                    <Col md="10">
                                        {movieData?.Data?.video_name}
                                    </Col>
                                    <Col md="2">
                                        Address
                                    </Col>
                                    <Col md="10">
                                        {movieData?.Data?.video_locality?.locality_name} , {movieData?.Data?.video_locality?.city} - {movieData?.Data?.video_locality?.pincode}
                                    </Col>
                                    <Col md="2">
                                        Description
                                    </Col>
                                    <Col md="10">
                                        {movieData?.Data?.video_description}
                                    </Col>
                                    <Col md="2">
                                        Package and Pricing
                                    </Col>
                                    <Col md="10">
                                        <Row style={{ border: "1px solid #ffffff", padding: "10px", margin: "0.5rem" }}>
                                            <Col md="4" sm="4" xs="6">
                                                Holiday
                                            </Col>
                                            <Col md="2" sm="2" xs="6">
                                                {Number(movieData?.Data?.holiday_price).toFixed(2)}
                                            </Col>
                                            <Col md="4" sm="4" xs="6">
                                                Group (5/10/20)
                                            </Col>
                                            <Col md="2" sm="2" xs="6">
                                                {Number(movieData?.Data?.group_price).toFixed(2)}
                                            </Col>
                                            <Col md="4" sm="4" xs="6">
                                                Weekend
                                            </Col>
                                            <Col md="2" sm="2" xs="6">
                                                {Number(movieData?.Data?.weekend_price).toFixed(2)}
                                            </Col>
                                            <Col md="4" sm="4" xs="6">
                                                Week days
                                            </Col>
                                            <Col md="2" sm="2" xs="6">
                                                {Number(movieData?.Data?.weekdays_price).toFixed(2)}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="2">
                                        Available Facilities
                                    </Col>
                                    <Col md="4">
                                        <ul>{
                                            movieData?.Data?.facility_type?.map((data, index) => (
                                                <li>{data?.facility_type}</li>
                                            ))}
                                        </ul>
                                    </Col>
                                    <Col md="2">
                                        Food Options
                                    </Col>
                                    <Col md="4">
                                        <ul>{
                                            movieData?.Data?.food_type?.map((data, index) => (
                                                <li>{data?.food_type}</li>
                                            ))}
                                        </ul>
                                    </Col>
                                    <Col md="2">
                                        You can book via
                                    </Col>
                                    <Col md="4">
                                        <ul>{
                                            movieData?.Data?.booking_type?.map((data, index) => (
                                                <li>{data?.booking_option}</li>
                                            ))}
                                        </ul>
                                    </Col>
                                    <Col md="6">
                                        Note : {movieData?.Data?.smoking_zone == "Yes" ? "Smoking is not permitted here." : "You may smoke here."}
                                    </Col>
                                    <Col md="4" style={{ textAlign: "center", border: "1px solid grey", padding: "5px" }}>
                                        Nearest Highway <br />
                                        <span style={{ color: "#e76932" }}> {movieData?.Data?.nearest_highway}</span>
                                    </Col>
                                    <Col md="4" style={{ textAlign: "center", border: "1px solid grey", padding: "5px" }}>
                                        Nearest Bus Station <br />
                                        <span style={{ color: "#e76932" }}> {movieData?.Data?.nearest_bus_station}</span>
                                    </Col>
                                    <Col md="4" style={{ textAlign: "center", border: "1px solid grey", padding: "5px" }}>
                                        Nearest Railway Station <br />
                                        <span style={{ color: "#e76932" }}>{movieData?.Data?.nearest_railway_station}</span>
                                    </Col>

                                    <Col md="12" className='mt-5' style={{ textAlign: "center" }}>
                                    {
                                            movieData?.Data?.mobile_number !== "" && movieData?.Data?.mobile_number && movieData?.Data?.mobile_number !== "null" &&
                                            <a href={`tel:${movieData?.Data?.mobile_number}`} target='_blank' className="btn btn-hover iq-button ml-2">
                                                <i className="fa fa-phone" aria-hidden="true"></i>
                                            </a>
                                        }
                                        {
                                            movieData?.Data?.catalogue !== "" && movieData?.Data?.catalogue && movieData?.Data?.catalogue !== "null" &&
                                            <a href={movieData?.Data?.catalogue} target='_blank' className="btn btn-hover iq-button mr-2 ml-2" >
                                                <i className="fa fa-link mr-2" aria-hidden="true"></i>Catalogue
                                            </a>
                                        }
                                        {
                                            movieData?.Data?.google_map_link !== "" && movieData?.Data?.google_map_link && movieData?.Data?.google_map_link !== "null" &&
                                            <a href={movieData?.Data?.google_map_link} target='_blank' className="btn btn-hover iq-button">
                                                <i className="fa fa-location-arrow mr-2" aria-hidden="true"></i>Direction
                                            </a>
                                        }
                                      
                                        <button className="btn btn-hover iq-button ml-2" onClick={handleRedirect}>
                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>Play
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        </section>




                        {/* Related movie data */}

                        <div className='main-content mt-3'>
                            <section id="iq-favorites">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12" className="overflow-hidden">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="main-title">Related Videos</h4>
                                                {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                                            </div>
                                            <div id="favorites-contens">
                                                {/* <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                    <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                                <Swiper
                                                    navigation={{
                                                        prevEl: '#prev',
                                                        nextEl: '#next'
                                                    }}
                                                    breakpoints={{
                                                        320: { slidesPerView: 1 },
                                                        550: { slidesPerView: 2 },
                                                        991: { slidesPerView: 3 },
                                                        1400: { slidesPerView: 4 }
                                                    }}
                                                    loop={false}
                                                    slidesPerView={6}
                                                    spaceBetween={0}
                                                    as="ul"
                                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                                                    {
                                                        movieData?.Related_Movie?.map((data, index) => (
                                                            <SwiperSlide as="li" key={index} style={{ cursor: "pointer" }} >
                                                                <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug)}>
                                                                    <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug)} className="image-container">

                                                                        <img src={`${LOCALITY_IMAGE_ENDPOINT}/${data?.poster_images?.[0]}`} className={`poster-img `} alt={data?.video_name} />
                                                                        <div className="image-overlay">{data?.video_name}</div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))
                                                    }


                                                </Swiper>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
            }
            <Modal
                open={hoursAlert}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Once you start watching this movie, you have 72 hours to finish it; if not, you must rent it again.
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>

                            <button onClick={handleHoursContinue} className={"btn btn-hover iq-button"}>
                                Watch Now
                            </button>
                            <button onClick={handleHoursDialogueClose} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={completedHour}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            You have exceeded 72 hours of movie watching limitation , please rent now to continue
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>
                            <button onClick={(handleHoursDialogueClose)} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}
export default AddMovie;

