import React from 'react'
import { Link} from 'react-router-dom'

const CustomToggle = React.forwardRef(({ children,variant, onClick }, ref) => (
    <Link
      to="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        var ele_array=document.getElementsByClassName("on_hover_openable")
        for(let i=0;i<ele_array.length;i++)
        {
          ele_array[i].classList.remove("show")
        }
        if(variant == "profile-toggle")
        onClick(e);
      }}
      
      className={variant}
    >
      {children}
      
    </Link>
));
  export default CustomToggle;