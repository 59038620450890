import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { bindActionCreators } from 'redux'
import * as HomeAction from '../../../actions/HomeActions'
import { Container } from 'react-bootstrap'
import { CircularProgress, Grid } from '@mui/material'
import { LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList'
import empty from '../../../assets/regal_images/empty-box (1).png'
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom'


export default function VideosByCategory() {
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { getVideosBySubCategory } = bindActionCreators(HomeAction, dispatch)
    const [videos, setVideos] = useState([])
    useEffect(() => {
        (async () => {
            console.log("location", location?.state)
            if (location?.state) {
                const resData = await getVideosBySubCategory({
                    category_id: location?.state?.category,
                    subcategory_id: location?.state?.subCategory
                })
                console.log("data", resData)
                setVideos(resData?.data)
            }
        })()
    }, [location?.state])

    const handleRedirect = (slug) => {
        console.log("slug", slug)
        history.push(ROUTES.moviedetails + '/' + slug)
    }

    return (
        <>
            <section className="m-profile setting-wrapper">
                <Container>
                    <div className='movielist-dropdwn' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h4 className="main-title mb-5 mt-3">Videos</h4>

                    </div>

                    <Grid container spacing={2}>
                        {
                            videos?.length ?
                                videos?.map((data, index) => (
                                    <Grid item lg={4} xl={4} md={6} sm={12} xs={6} key={index} className='grid-img-cls trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction '>
                                        {/* <img src={`${LOCALITY_IMAGE_ENDPOINT + data?.video_thumbnail}`} onClick={()=>history.push(ROUTES.video, { video: data })} className="thumb-img-filter" alt={data?.movie_name} /> */}
                                        <div className="image-container" style={{cursor:"pointer"}}>
                                            <Link to={ROUTES.moviedetails + '/' + data?.slug}><img src={`${LOCALITY_IMAGE_ENDPOINT + data?.video_poster}`} className="series-img" alt={data?.movie_name} />
                                                <div className="image-overlay">{data?.video_name}</div>
                                            </Link>
                                        </div>
                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                        {/* <CircularProgress /> */}
                                    </div>
                                </Grid>
                        }

                    </Grid>
                </Container>
            </section>
        </>
    )
}
