import React, { useEffect, useState } from 'react'
import Slider from './Slider'
import ContinueWatching from './ContinueWatching'
import PopularMovies from './PopularMovies'
import Genres from './Genres'
import RecommendedMovies from './RecommendedMovies'
import MovieBanner from './Promotion'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import * as HomeAction from '../../../actions/HomeActions'
import UpcomingMovies from './UpcomingMovies'
import Category from './Category'
import WebSeries from './WebSeries'
import Language from './Language'
import RemainingCategory from './RemainingCategory'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import logo_gif from "../../../assets/regal_images/spinner.gif"
import { CircularProgress } from '@mui/material'
import Artist from './Artist'
import LeavingSoon from './LeavingSoon'
import Highlights from './Highlights'
import Promotion from './Promotion'


export default function Home() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
  const { getHighlightList } = bindActionCreators(HomeAction, dispatch)
  const { getPromotionList } = bindActionCreators(HomeAction, dispatch)
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const login_details = localStorage.getItem("login_details")
  const isLoggedIn = sessionStorage.getItem('isLoggedIn')
  const [home, setHome] = useState()
  const [highLights, setHighLights] = useState()
  const [promotion, setPromotion] = useState()
  const [loaderCompletion, setLoaderCompletion] = useState(false)
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    setTimeout(() => { setLoaderCompletion(true) }, 4500)
    if (login_details && !isLoggedIn) {

      dispatch(HomeAction.login(JSON.parse(login_details), history))
    }
    (async () => {
      let userObj = {
        user_id: user_id ? user_id : "null",
        profile_id: profile_id ? profile_id : "null"
      }
      const resData = await getAllHomeData(userObj)
      setHome(resData?.data)
      const highLightsData = await getHighlightList(userObj)
      setHighLights(highLightsData?.data)
      const promotionData = await getPromotionList(userObj)
      setPromotion(promotionData?.data)
      // console.log(resData,highLightsData,promotionData)
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    })()
  }, [isLoggedIn])

  useEffect(() => {
    const updateCounter = () => {
      setCounter((prevCounter) => prevCounter + 1);
    };
    const timerId = setTimeout(updateCounter, 2000);
    return () => {
      clearTimeout(timerId);
    };
  }, [counter]);

  return (
    <>
      <Slider />

      {/* {(home) ? <>

        {home?.map((ele,index) => 
        <div key={index}>
        {index==5&&<Highlights highLights={highLights}/>}
          {index==3&&<Promotion promotion={promotion}/>}
        <Category category={ele} />
        </div>
        )}
      </> : <div style={{ display: 'flex', justifyContent: "center", height: "100vh", alignItems: "center" }}>
        <CircularProgress />

      </div>} */}
    </>
  )
}
