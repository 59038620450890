import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import OTPInput, { ResendOTP } from "otp-input-react";
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constant/routes';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as HomeAction from '../../../../actions/HomeActions'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { LOGIN_MESSAGE, LOGOUT_MESSAGE } from '../../../../Actiontype/ActionType';

export default function Logout_Otp() {
    const [OTP, setOTP] = useState("");
    const dispatch = useDispatch()
    const { forgot_password_verify_otp, user_logout } = bindActionCreators(HomeAction, dispatch)
    const user = JSON.parse(sessionStorage.getItem('user'))
    const location = useLocation()
    const history = useHistory()

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("otp", OTP)
        let userObj = {
            username: user?.email,
            OTP: OTP
        }
        const verify_otp = await forgot_password_verify_otp(userObj)
        console.log(verify_otp)
        if (verify_otp?.statuscode == 200) {
            console.log(location?.state)
            location?.state?.device?.map(async (ele) => {
                const res = await user_logout({ user_id: ele?.user, id: ele?.id, login_type: ele?.login_type })

                // dispatch(user_logout({ user_id: ele?.user, ip_address: ele?.ip_address,login_type: ele?.login_type }))
            } )
            toast.success("You have logged out successfully.")
            setTimeout(() => {
                history.push(ROUTES.home)
            }, 2000);
            sessionStorage.setItem("profile", "{}")
            sessionStorage.setItem("user", "{}")
            sessionStorage.setItem("isLoggedIn", false)
            localStorage.setItem("login_details", "{}")
            // toast.success("You have logged out successfully.")
            // setTimeout(() => {
            //     history.push(ROUTES.home)
            // }, 2000);
        }
        else {
            toast.error(verify_otp?.message)
        }
    }

    return (
        <>
            <section className="sign-in-page">

                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <form onSubmit={handleSubmit}>
                                        <div className="sign-in-from w-100 m-auto" style={{ textAlign: "center" }}>


                                            <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "700", fontSize: "24px" }}>Please verify to logout.</p>
                                            <p style={{ textAlign: "center" }}>Enter OTP shared on your registered mail.</p>

                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}>
                                                <OTPInput
                                                    value={OTP}
                                                    onChange={setOTP}
                                                    autoFocus
                                                    OTPLength={4}
                                                    otpType="number"
                                                    inputStyles={{
                                                        height: "50px",
                                                        width: "50px",
                                                        border: "1px solid white",
                                                    }}
                                                />

                                            </div>
                                            <div>
                                                <p className='mt-4'>Resend OTP</p>
                                                <button type='submit' className="btn btn-hover mb-2 iq-button sub-btn mt-2">
                                                    Submit
                                                </button><br />
                                                <Link to={ROUTES.device_management} style={{ cursor: "pointer" }}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</Link>

                                            </div>



                                        </div>
                                    </form>

                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
