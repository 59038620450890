import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { user_details_web } from '../../../../actions/HomeActions';
import { ROUTES } from '../../../../constant/routes';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Grid } from '@mui/material';
const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const My_Account = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    console.log(user, "acc")
    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);
    const user_details = useSelector((state) => state.home.user_details?.data)
    const user_plan_expired = useSelector((state) => state.home.user_details)

    useEffect(() => {

        dispatch(user_details_web({ user_id: user?.id }))

    }, [])
    console.log(user_plan_expired, "my_account")

    const handleClick = () => {
        history.push('/profile_selection', { back: true })
    }

    return (
        <>
            <section className="m-profile setting-wrapper mt-5">
                <Container>
                    <Row style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <Col lg="6">
                            <div className='mb-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <h5 className="main-title">Personal Details</h5>
                            </div>
                            <div className="" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        Name
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user?.role == "resort_manager" ? user?.person_name : (user?.firstName + " " + user?.lastName)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Email
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user?.email}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Mobile No.
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user?.mobileNumber}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Role
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user?.role == "resort_manager" ? "Rosort Manager" : "Visitor"}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mt-2" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <Link to="/change_password">
                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <LockOpenIcon />
                                                <span className='pl-2'>Change Password</span>
                                            </div>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(My_Account)