import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import user from '../../../../assets/images/user/user.jpg'
import { Grid } from '@mui/material'
import HttpsIcon from '@mui/icons-material/Https';
import { ROUTES } from '../../../../constant/routes'
import GroupIcon from '@mui/icons-material/Group';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DevicesIcon from '@mui/icons-material/Devices';
import LockOpenIcon from '@mui/icons-material/LockOpen';
const UserAccountSetting = () => {
   return (
      <>
         <section className="m-profile setting-wrapper">
            <Container>
               <Row style={{ alignItems: "center" }}>
                  <Col lg="4" className="">
                     <h5 className="main-title mb-4">My Account</h5>

                     <div className="sign-user_card text-center">
                        <img style={{ height: "150px" }} src={'http://122.170.109.73:5554/OTT/static/image/avatar/avatar12345-removebg-preview_OvhYW3q.png'} className="rounded-circle img-fluid d-block mx-auto mb-3" alt="user" />
                        <h4 className="mb-3">Harshil Patel</h4>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
                        {/* <Link to="#" className="edit-icon text-primary">Edit</Link> */}
                     </div>
                  </Col>
                  <Col lg="8">
                     <div className='mb-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                        <h5 className="main-title">Personal Details</h5>
                        <Link to={ROUTES.edit_account} style={{ color: "#ffc328" }}>
                           <i className="fa fa-pencil-square" style={{ fontSize: "40px" }} aria-hidden="true"></i>
                        </Link>
                     </div>
                     <div className="" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                        <Grid container spacing={2}>
                           <Grid item xs={4}>
                              Email
                           </Grid>
                           <Grid item xs={8}>
                              pharshil5603@gmail.com
                           </Grid>
                           <Grid item xs={4}>
                              Mobile No.
                           </Grid>
                           <Grid item xs={8}>
                              +91 9104592065
                           </Grid>
                           <Grid item xs={4}>
                              Date of Birth
                           </Grid>
                           <Grid item xs={8}>
                              05/06/2003
                           </Grid>
                        </Grid>
                     </div>
                     {/* <h5 className="main-title mb-2 mt-3">Plan Details</h5> */}
                     <div className="mt-2" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                        <Grid container spacing={2}>
                           <Grid item xs={6}>
                              Current Plan : <span style={{ color: "yellow" }}>Basic</span>
                           </Grid>
                           <Grid item xs={6}>
                              Expired On : <span style={{ color: "yellow" }}>03/04/2024</span>
                           </Grid>
                           <Grid item xs={12}>
                              <Link to="/price-plan" className="btn btn-hover iq-button" style={{ width: "100%" }}>Upgrade Plan</Link>
                           </Grid>
                           <Grid item xs={6} >
                              <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                 <Grid item style={{ textAlign: "end" }} xs={4}>
                                    <LockOpenIcon />
                                 </Grid>
                                 <Grid item style={{ textAlign: "left" }} xs={8}>
                                    <span className='pl-2'>Change Password</span>
                                 </Grid>
                              </div>
                           </Grid>
                           <Grid item xs={6}>
                              <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                 <Grid item style={{ textAlign: "end" }} xs={4}>
                                    <GroupIcon />
                                 </Grid>
                                 <Grid item style={{ textAlign: "left" }} xs={8}>
                                    <span className='pl-2'>Manage Profiles</span>
                                 </Grid>
                              </div>
                           </Grid>
                           <Grid item xs={6}>
                              <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                 <Grid item style={{ textAlign: "end" }} xs={4}>
                                    <ReceiptLongIcon />
                                 </Grid>
                                 <Grid item style={{ textAlign: "left" }} xs={8}>
                                    <span className='pl-2'>Transaction History</span>
                                 </Grid>
                              </div>
                           </Grid>
                           <Grid item xs={6}>
                              <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                 <Grid item style={{ textAlign: "end" }} xs={4}>
                                    <DevicesIcon />
                                 </Grid>
                                 <Grid item style={{ textAlign: "left" }} xs={8}>
                                    <span className='pl-2'>Device Management</span>
                                 </Grid>
                              </div>
                           </Grid>

                        </Grid>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}

export default UserAccountSetting