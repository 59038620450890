import React,{useState, useEffect, useMemo}  from 'react'
import {Link} from 'react-router-dom'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import {about_us} from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
//img
import aboutus1 from '../../../../assets/images/Bg.jpg'

const PrivacyPolicy = () => {
    const dispatch=useDispatch()
    useEffect(()=>{dispatch(about_us())},[])
    const data = useSelector((state) => state.home.about_us);
    return (
        <>
           
            <main id="main" className="site-main">
                <Container>
                <h4 className="title" style={{margin:"2rem 0",textAlign:"center"}}>About Us</h4>
                    <Row>
                        <Col lg="12" sm="12">
                            <div className="iq-privacy-policy">
                                <div style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:data?.data?.[0]?.description}}>
                                    
                                </div>
                                
                            </div>
                        </Col>
                        {/* <Col lg="12" sm="12">
                          
                                <h4 style={{textAlign:"center",marginBottom:"2rem"}}>Contact Us</h4>
                                <div style={{display:"flex",justifyContent:"space-around"}}>
                                    <p>E-mail : <span style={{color:"white",fontWeight:"600"}}>{data?.data?.[0]?.email}</span></p>
                                    <p>Mobile Number : <span style={{color:"white",fontWeight:"600"}}>{data?.data?.[0]?.mobileNumber}</span></p>
                                </div>
                           
                        </Col>
                        <Col lg="12" sm="12" >
                                <h4 style={{textAlign:"center",marginBottom:"2rem"}}>
                                    Follow Us 
                                </h4>
                                <div style={{display:"flex",justifyContent:"space-around"}}>
                                   <Link target="_blank" to="{data?.data?.[0]?.facebook_url}"><i className="fa fa-facebook" style={{fontSize:"5vw"}}></i></Link>
                                   <Link target="_blank" to="{data?.data?.[0]?.instagram_url}"><i className="fa fa-instagram" style={{fontSize:"5vw"}}></i></Link>
                                   <Link target="_blank" to="{data?.data?.[0]?.youtube_url}"><i className="fa fa-youtube" style={{fontSize:"5vw"}}></i></Link>
                                   <Link target="_blank" to="{data?.data?.[0]?.linkedln_url}"><i className="fa fa-linkedin" style={{fontSize:"5vw"}}></i></Link>
                                   </div>

                            </Col> */}
                    </Row>
                </Container>
            </main>
        </>
    )
}

export default PrivacyPolicy;