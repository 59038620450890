import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Row, Col, Container, Button, Card, DropdownButton } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'

import { CircularProgress, Grid } from '@mui/material';
import { ROUTES } from '../../../constant/routes';
import { IMAGE_ENDPOINT, LOCALITY_IMAGE_ENDPOINT } from '../../../constant/ApiList';
import empty from '../../../assets/regal_images/empty-box (1).png'
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

//img

// install Swiper modules
SwiperCore.use([Navigation]);

const MovieList = () => {
    const dispatch = useDispatch()
    const { getAllVideos } = bindActionCreators(MovieAction, dispatch)
    const { getAllStates, getCityByState, getAreaByCity } = MovieAction
    const { getAllCategories } = bindActionCreators(HomeAction, dispatch)
    const [allMovies, setAllMovies] = useState([])
    const [movies, setMovies] = useState([])

    const [categories, setCategories] = useState([])

    const [filtered, setFiltered] = useState("Filter by Category")
    const [stateFiltered, setStateFiltered] = useState("Select State")
    const [cityFiltered, setCityFiltered] = useState("Select City")
    const [areaFiltered, setAreaFiltered] = useState("Select Area")
    const [isApplied, setIsAppled] = useState(false)


    const [allCat, setAllCat] = useState([])
    const history = useHistory()
    const [allState, setAllState] = useState({})
    const [allCity, setAllCity] = useState({})
    const [allArea, setAllArea] = useState({})

    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    useEffect(() => {
        (async () => {
            let userObj = {
                category_name: "MOVIES"
            }
            const resData = await getAllVideos(userObj)
            const allState = await getAllStates()
            const res = await getAllCategories()
            console.log(resData)
            setAllState(allState)
            setAllCat(res?.data)
            setMovies(resData?.data)
            setAllMovies(resData?.data)
            setCategories(resData?.data)

        })();
    }, [])


    const handleFilter = (dataName) => {
        let localArr = []

        setFiltered(dataName)
        categories?.filter((data) => {
            if (data?.video_category == dataName) {
                localArr.push(data)
            }
        })
        setMovies(localArr)

    }

    const handleStateFilter = async (data) => {
        setStateFiltered(data?.state)
        const resData = await getCityByState({ state: data?.id })
        console.log(resData)
        setAllCity(resData)
    }

    const handleCityFilter = async (data) => {
        setCityFiltered(data?.city)
        const resData = await getAreaByCity({ city: data?.id })
        console.log(resData)
        setAllArea(resData)
    }

    const handleAreaFilter = (data) => {
        console.log(data)
        setAreaFiltered(data?.area)
        history.push(`${ROUTES.videoByArea + "/" + data?.slug}/${data?.area_pin_code}`)

    }

    const handleLocationFilter = () => {
        console.log(cityFiltered, stateFiltered, areaFiltered)
        let localArr = []
        if (stateFiltered == "Select State") {
            toast.error("Please select state")
        }
        else if (cityFiltered == "Select City") {
            toast.error("Please select city")
        }
        else if (areaFiltered == "Select Area") {
            toast.error("Please select area")
        }
        else {

            setIsAppled(true)

            allMovies?.filter((data) => {
                if (data?.video_area?.area == areaFiltered && data?.video_area?.city?.city == cityFiltered && data?.video_area?.state?.state == stateFiltered) {
                    console.log(data)
                    localArr.push(data)

                }
            })
            setMovies(localArr)

        }
    }

    const removeFilter = () => {
        setIsAppled(false)
        setStateFiltered("Select State")
        setCityFiltered("Select City")
        setAreaFiltered("Select Area")
        setMovies(allMovies)
    }
    return (
        <>
            <section className="m-profile setting-wrapper">
                <Container>
                    <div className='movielist-dropdwn mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h4 className="main-title mb-5 mt-3">Videos</h4>
                        <div className='dropdown-btn-genre' style={{ display: "flex" }}>
                            <DropdownButton id="dropdown-item-button" className=' mr-2' title={stateFiltered}>
                                <div style={{ maxHeight: '200px', overflowY: 'auto', background: "#000000" }} className='remove-scroll'>
                                    {
                                        allState?.data?.map((data, index) => (
                                            <Dropdown.Item key={index} style={{ cursor: "pointer", color: "white" }} as="option" value={'SVOD'} onClick={() => handleStateFilter(data)}>
                                                {data?.state}
                                            </Dropdown.Item>
                                        ))
                                    }


                                </div>
                            </DropdownButton>
                            <DropdownButton id="dropdown-item-button" className=' mr-2' title={cityFiltered}>
                                <div style={{ maxHeight: '200px', overflowY: 'auto', background: "#000000" }} className='remove-scroll'>
                                    {

                                        allCity?.data?.map((data, index) => (
                                            <Dropdown.Item key={index} style={{ cursor: "pointer", color: "white" }} as="option" value={'SVOD'} onClick={() => handleCityFilter(data)}>
                                                {data?.city}
                                            </Dropdown.Item>
                                        ))
                                    }


                                </div>
                            </DropdownButton>
                            <DropdownButton id="dropdown-item-button" className=' mr-2' title={areaFiltered}>
                                <div style={{ maxHeight: '200px', overflowY: 'auto', background: "#000000" }} className='remove-scroll'>
                                    {

                                        allArea?.data?.map((data, index) => (
                                            <Dropdown.Item key={index} style={{ cursor: "pointer", color: "white" }} as="option" value={'SVOD'} onClick={() => handleAreaFilter(data)}>
                                                {data?.area}
                                            </Dropdown.Item>
                                        ))
                                    }


                                </div>
                            </DropdownButton>
                            {/* <button className='btn btn-hover iq-button my-btn' onClick={isApplied ? removeFilter : handleLocationFilter}>{isApplied ? "Remove" : "Apply"}</button> */}
                            {/* <DropdownButton id="dropdown-item-button" title={filtered}>
                                <div style={{ maxHeight: '200px', overflowY: 'auto', background: "#000000" }} className='remove-scroll'>
                                    {
                                        allCat?.map((data, index) => (
                                            <Dropdown.Item key={index} style={{ cursor: "pointer", color: "white" }} as="option" value={'SVOD'} onClick={() => handleFilter(data?.category_name)}>
                                                {data?.category_name}
                                            </Dropdown.Item>
                                        ))
                                    }


                                </div>
                            </DropdownButton> */}
                        </div>
                    </div>

                    <Grid container spacing={2}>
                        {
                            movies?.length ?
                                movies?.map((data, index) => (
                                    <Grid style={{ cursor: "pointer" }} item lg={4} xl={4} md={6} sm={6} xs={12} key={index} className='grid-img-cls trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction'>
                                        <div onClick={() => history.push(ROUTES.moviedetails + '/' + data?.slug)} className="image-container">
                                            <img src={`${LOCALITY_IMAGE_ENDPOINT + data?.video_poster}`} className="series-img" alt={data?.movie_name} />
                                            <div className="image-overlay">{data?.video_name}</div>
                                            {/* <Card className='hover-class'>
                                                <Card.Img variant="top" src={`${LOCALITY_IMAGE_ENDPOINT}/${data?.video_poster}`} />
                                                <Card.Body style={{ marginTop: "-1rem" }}>
                                                    <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                                    </span><br />
                                                    <div style={{ marginTop: "-0.3rem" }}>
                                                        <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                                        </span>
                                                        <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                                        <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                            <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Card> */}
                                        </div>
                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                        {/* <CircularProgress /> */}
                                    </div>
                                </Grid>
                        }

                    </Grid>
                </Container>
            </section>
        </>
    )
}
export default MovieList;