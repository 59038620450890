import axios from "axios";
import { GET_EPISODE_DETAILS_API, GET_SERIES_DETAILS_API, LIKED_SERIES_API } from "../constant/ApiList";
import { GET_EPISODE_DETAILS, GET_SERIES_DETAILS, GET_SERIES_DETAILS_ERROR } from "../Actiontype/ActionType";
import { Base64 } from "js-base64";

export const getSeriesDetails = (slug, userObj) => {
  
}

export const addToLikedSeries = async (userObj) => {
   
}


export const getEpisodeDetails = (userObj) => {
 
}