import React,{useState, useEffect, useMemo}  from 'react'

import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import {terms_and_conditions} from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
//img
import aboutus1 from '../../../../assets/images/Bg.jpg'

const TermsConditions = () => {
    const dispatch=useDispatch()
    useEffect(()=>{dispatch(terms_and_conditions())},[])
    const data = useSelector((state) => state.home.terms_conditions);
    return (
        <>
          
            <main id="main" className="site-main">
                <Container>
                <h4 className="title" style={{margin:"2rem 0"}}>Terms & Conditions</h4>

                    <Row>
                        <Col lg="12" sm="12">
                            <div className="iq-privacy-policy">
                                <div dangerouslySetInnerHTML={{__html:data?.data?.[0]?.description}}></div>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    )
}

export default TermsConditions;