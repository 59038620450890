import React, { useEffect, useState, useMemo } from 'react'
import VideoJS from './player'
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import { useHistory } from 'react-router-dom';
import "videojs-markers"
import { useDispatch, useSelector } from 'react-redux';
// import { advertise_seen_count_change } from '../actions/MovieAction';
import { bindActionCreators } from 'redux';
import * as MovieAction from '../actions/MovieAction'
import left_arrow from "../assets/regal_images/left-arrow.png"
import logo from '../assets/regal_images/regal_logo.png'


import axios from "axios";
import { Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { RUNNING_STATUS } from '../Actiontype/ActionType';
import * as HomeAction from '../actions/HomeActions';
import { IMAGE_ENDPOINT } from '../constant/ApiList';
import { ROUTES } from '../constant/routes';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "70vh",
  width: 400,
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

require("@silvermine/videojs-quality-selector")(videojs);

export default function VideoFile() {

  const playerRef = React.useRef(null);
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [videoData, setVideoData] = useState(location?.state?.video)
  const [adsFlag, setAdsFlag] = useState(location?.state?.movie?.advertise?.map((ele) => true) || [])
  const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const { addContinueWatching } = bindActionCreators(MovieAction, dispatch)
  const { change_running_status, VideoSeenCount } = bindActionCreators(HomeAction, dispatch)
  const { live_movie_view_count, advertise_seen_count_change } = MovieAction


  const running_status = useSelector((state) => state.home.running_status)
  const [popupOpen, setPopupOpen] = useState(false)
  const [ip, setIp] = useState("")
  useEffect(() => {

    (async () => {
      if (location?.state) {
        const resData = await VideoSeenCount({ video: location?.state?.video?.id, user: user_id })
        setVideoData(location?.state?.video)

        console.log("tempAdsFlag", location?.state)
        const res = await axios.get("https://api.ipify.org/?format=json");
        // const result = await change_running_status({
        //   user: user_id,
        //   login_type: "Web",
        //   running_status: "Running",
        //   ip_address: res.data.ip,
        //   access_type: location?.state?.movie?.Data?.movie_access,
        //   movie_id: location?.state?.movie?.Data?.id,
        //   episode_id: "",
        //   content_type: location?.state?.movie?.category_type
        // })
        // dispatch(change_running_status({ user: user_id, login_type: "Web", running_status: "Running", ip_address: res.data.ip }))
        setIp(res.data.ip)

      }
    })()

  }, [location?.state])

  useMemo(async () => {
    // setAdsFlag(videoData?.advertise?.map((ele) => true))
    let userObj = {
      movie: location?.state?.movie?.Data?.id,
      status: "Running"
    }
    // const send_status = await live_movie_view_count(userObj)

  }, [location?.state?.movie?.Data?.id])

  useMemo(async () => {
    setAdsFlag(location?.state?.movie?.advertise?.map((ele) => true) || [])


  }, [videoData])


  const videoJsOptions = {
    autoplay: true,
    loop: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: false,
    title: videoData?.video_name,
    textTrackSettings: false,
    // playbackRates: [0.5, 1, 1.5, 2],
    userActions: { hotkeys: true },
    controlBar: {
      qualitySelector: true,
      fullscreenToggle: true,
      volumePanel: { inline: false },
      pictureInPictureToggle: false,
      skipButtons: {
        forward: 10,
        backward: 10
      }

    },




    sources: [
      {
        src: `${videoData?.video_link_mp4}`,
        type: 'video/mp4',
      }
    ]

  };


  const handlePlayerReady = (player) => {
    var currentTime = 0;
    playerRef.current = player;




    player.controlBar.addChild('MenuButton', {
      className: 'audio_subtitle_resolution_controls',
      clickHandler: function () {
        if (!player.paused()) {
          player.pause()
        }

      }

    })

    player?.addChild("QualitySelector")
    player.addChild("fullscreenToggle")
    player.controlBar.removeChild("QualitySelector")

    player.controlBar.removeChild("fullscreenToggle")
    player.titleBar.removeClass('vjs-hidden')


    player.addChild('button', {
      className: 'go-back',
      clickHandler: function (event) {
        history.goBack()
      }
    });


    player.on("userinactive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "none"
      document.getElementsByClassName(" go-back")[0].style.display = "none"



    })
    player.on("useractive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "block"
      document.getElementsByClassName(" go-back")[0].style.display = "block"
    })

    player.on('waiting', () => {
      videojs.log('player is waiting');
    });


  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    history.goBack()
    dispatch({ type: RUNNING_STATUS, payload: null })
  };

  useMemo(() => {
    if (running_status?.statuscode == 406) {
      setPopupOpen(true)



    }
  }, [running_status])

  return (
    <>
      <Modal
        open={popupOpen}
        onClose={handlePopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} width={"100px"} />
          </div>
          <hr />

          <div className="login-popup" >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
              {running_status?.message}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>

              <button onClick={handlePopupClose} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {!popupOpen && <div className='video-parent video'>
        <VideoJS playerRef={playerRef} options={videoJsOptions} onReady={handlePlayerReady} />
      </div>}
      {/* <div onClick={() => history.goBack()} className='go-back' ><img src={left_arrow} width={"40px"}/></div> */}

    </>
  )
}


