import React, { useEffect, useMemo, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './player.css';// import 'videojs-resolution-switcher'
import "videojs-contrib-quality-levels";
require('videojs-quality-selector-hls');

export const VideoJS = (props) => {



  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;


  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      videoElement?.classList?.add('vjs-big-play-centered');
      videoRef?.current?.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });
      player.qualitySelectorHls();

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      // player.addChild('customMenu', {});
      player?.autoplay(options?.autoplay);
      player?.src(options?.sources);
      document?.getElementsByClassName("video-title")[0]?.remove()
      player.titleBar.addChild('button', {
        controlText: `${options?.title}`,
        className: 'vjs-visible-text video-title',
        position: 2,
      });
      
    }

  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;


    return () => {

      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>

      <div className="video-container iq-main-slider" style={props?.style} id='vjs-big-play-centered'>
        <div className="video d-block" ref={videoRef} />

      </div>
    </>
  );
}

export default VideoJS;