import axios from "axios";
import { ADD_CONTINUE_WATCHING, ADD_CONTINUE_WATCHING_ERROR, ADD_MOVIE_IN_WATCHLIST, ADD_MOVIE_IN_WATCHLIST_ERROR, GET_ALL_MOVIES_BY_GENRE, GET_ALL_MOVIES_BY_GENRE_ERROR, GET_ALL_MOVIES_BY_LANGUAGE, GET_ALL_MOVIES_BY_LANGUAGE_ERROR, GET_ALL_SERIES, GET_ALL_SERIES_ERROR, GET_ALL_VIDEOS, GET_ALL_VIDEOS_ERROR, GET_CONTINUE_WATCHING, GET_CONTINUE_WATCHING_ERROR, GET_LEAVING_SOON_MOVIES, GET_LEAVING_SOON_MOVIES_ERROR, GET_RECOMMENDED_MOVIES, GET_RECOMMENDED_MOVIES_ERROR, GET_USER_WATCHLIST, GET_USER_WATCHLIST_ERROR } from "../Actiontype/ActionType";
import { ADD_CONTINUE_WATCHING_API, GET_SUB_CATEGORY_SEARCH_API, ADD_MOVIE_IN_WATCHLIST_API, DELETE_CONTINUE_WATCHING_API, GET_ALL_MOVIES_BY_GENRE_API, GET_ALL_MOVIES_BY_LANGUAGE_API, GET_ALL_SERIES_API, GET_ALL_VIDEOS_API, GET_CONTINUE_WATCHING_API, GET_LEAVING_SOON_MOVIES_API, GET_RECOMMENDED_MOVIES_API, GET_USER_WATCHLIST_API, LIKED_CONTENT_API, LIVE_MOVIE_COUNT_API, REMOVE_MOVIE_FROM_WATCHLIST_API, SEARCH_API, SET_TVOD_MOVIE_HOURS_API, advertise_seen_count_change_api, GET_ALL_STATE_API, GET_ALL_CITY_API, GET_ALL_AREA_API, GET_VIDEOS_BY_AREA } from "../constant/ApiList";
import { change_running_status } from "./HomeActions";
import { Base64 } from "js-base64";


export const getUserWatchlist = (userObj) => {

}

export const addToLikedContent = async (userObj) => {

}

export const getAllVideos = (userObj) => {
    return async (dispatch) => {

        return await axios
            .post(GET_ALL_VIDEOS_API).then((res) => {
                dispatch({
                    type: GET_ALL_VIDEOS,
                    payload: res.data
                })
                return res.data
            }).catch((error) => {
                dispatch({
                    type: GET_ALL_VIDEOS_ERROR
                })
                return error.response
            })
    }
}

export const getAllStates = async () => {
    // return async (dispatch) => {

    return await axios
        .post(GET_ALL_STATE_API).then((res) => {
            // dispatch({
            //     type: GET_ALL_VIDEOS,
            //     payload: res.data
            // })
            return res.data
        }).catch((error) => {
            // dispatch({
            //     type: GET_ALL_VIDEOS_ERROR
            // })
            return error.response
        })
    // }
}
export const getCityByState = async (userObj) => {

    return await axios
        .post(GET_ALL_CITY_API, userObj).then((res) => {

            return res.data
        }).catch((error) => {

            return error.response
        })

}
export const getVideosByArea = async (slug) => {

    return await axios
        .post(`${GET_VIDEOS_BY_AREA}/${slug}/`).then((res) => {

            return res.data
        }).catch((error) => {

            return error.response
        })

}

export const getAreaByCity = async () => {

    return await axios
        .post(GET_ALL_AREA_API).then((res) => {

            return res.data
        }).catch((error) => {

            return error.response
        })

}
export const getAllSeries = (userObj) => {

}

export const getContinueWatchingData = (userObj) => {

}

export const deleteContinueWatching = async (userObj) => {

}


export const getAllMoviesByLanguage = (formData) => {

}

export const getAllMoviesByGenre = (formData) => {

}

export const addIntoWatchList = (userObj) => {

}


export const removeFromWatchlist = async (userObj) => {

}

export const getSearchSuggestion = async (userObj) => {
    const res = await axios.get("https://api.ipify.org/?format=json");

    return await axios
        .post(SEARCH_API, { ...userObj, ip_address: res.data.ip })
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}

export const advertise_seen_count_change = (formData) => async () => {

};


export const live_movie_view_count = async (userObj) => {

}

export const addContinueWatching = (userObj) => {

}


export const setCompleteHours = async (userObj) => {

}

export const getRecommendedMovies = (userObj) => {

}

export const getLeavingSoonMovies = (userObj) => {

}

export const getSubCategorySearch = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(GET_SUB_CATEGORY_SEARCH_API, userObj).then((res) => {
                console.log(res)
                return res.data
            }).catch((error) => {
                return error.response
            })
    }
}