import React from 'react'
import aboutus1 from '../../../assets/images/about-us/01.jpg'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// tvthrillers
import tvthrillers1 from '../../../assets/images/tvthrillers/01.jpg'
import tvthrillers2 from '../../../assets/images/tvthrillers/02.jpg'
import tvthrillers3 from '../../../assets/images/tvthrillers/03.jpg'
import tvthrillers4 from '../../../assets/images/tvthrillers/04.jpg'
import tvthrillers5 from '../../../assets/images/tvthrillers/05.jpg'

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';

export default function LiveTV() {
    return (
        <>
            <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${aboutus1})` }}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col sm="12">
                            <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                <h2 className="title">Live TV</h2>
                                <Breadcrumb className="main-bg">
                                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Live Tv</Breadcrumb.Item>
                                </Breadcrumb>
                            </nav>
                        </Col>
                    </Row>

                </Container>
            </div>
            <section id="iq-tvthrillers" className="s-margin mb-5">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="main-title">Recommended For You</h4>
                                <Link className="iq-view-all" to="/movie-category">View All</Link>
                            </div>
                            <div id="tvthrillers-contens">
                                <div id="prev3" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                <div id="prev3" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                                <Swiper
                                    navigation={{
                                        prevEl: '#prev3',
                                        nextEl: '#next3'
                                    }}
                                    breakpoints={{
                                        320: { slidesPerView: 1 },
                                        550: { slidesPerView: 2 },
                                        991: { slidesPerView: 3 },
                                        1400: { slidesPerView: 4 }
                                    }}
                                    loop={true}
                                    slidesPerView={4}
                                    spaceBetween={20}
                                    as="ul"
                                    className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction">
                                    <SwiperSlide as="li">
                                        <div className=" block-images position-relative">
                                            <div className="img-box">
                                                <img src={tvthrillers1} className="img-fluid" alt="" />
                                            </div>
                                            <div className="block-description">
                                                <h6 className="iq-title"><Link to="/show-details">Day of Darkness</Link></h6>
                                                <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                                    <div className="badge badge-secondary p-1 mr-2">15+</div>
                                                    <span className="text-white">2 Seasons</span>
                                                </div>
                                                <div className="hover-buttons">
                                                    <Link to="/show-details" role="button" className="btn btn-hover iq-button">
                                                        <i className="fa fa-play mr-1" aria-hidden="true"></i>Play Now
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="block-social-info">
                                                <ul className="list-inline p-0 m-0 music-play-lists">
                                                    <li className="share">
                                                        <span><i className="ri-share-fill"></i></span>
                                                        <div className="share-box">
                                                            <div className="d-flex align-items-center">
                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-heart-fill"></i></span>
                                                        <span className="count-box">19+</span>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-add-line"></i></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide as="li">
                                        <div className="block-images position-relative">
                                            <div className="img-box">
                                                <img src={tvthrillers2} className="img-fluid" alt="" />
                                            </div>
                                            <div className="block-description">
                                                <h6 className="iq-title"><Link to="/show-details">My True Friends</Link></h6>
                                                <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                                    <div className="badge badge-secondary p-1 mr-2">7+</div>
                                                    <span className="text-white">2 Seasons</span>
                                                </div>
                                                <div className="hover-buttons">
                                                    <Link to="/show-details" role="button" className="btn btn-hover iq-button">
                                                        <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                                        Play Now
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="block-social-info">
                                                <ul className="list-inline p-0 m-0 music-play-lists">
                                                    <li className="share">
                                                        <span><i className="ri-share-fill"></i></span>
                                                        <div className="share-box">
                                                            <div className="d-flex align-items-center">
                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-heart-fill"></i></span>
                                                        <span className="count-box">19+</span>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-add-line"></i></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide as="li">
                                        <div className="block-images position-relative">
                                            <div className="img-box">
                                                <img src={tvthrillers3} className="img-fluid" alt="" />
                                            </div>
                                            <div className="block-description">
                                                <h6 className="iq-title"><Link to="/show-details">Arrival 1999</Link></h6>
                                                <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                                    <div className="badge badge-secondary p-1 mr-2">11+</div>
                                                    <span className="text-white">3 Seasons</span>
                                                </div>
                                                <div className="hover-buttons">
                                                    <Link to="/show-details" role="button" className="btn btn-hover iq-button">
                                                        <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                                        Play Now
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="block-social-info">
                                                <ul className="list-inline p-0 m-0 music-play-lists">
                                                    <li className="share">
                                                        <span><i className="ri-share-fill"></i></span>
                                                        <div className="share-box">
                                                            <div className="d-flex align-items-center">
                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-heart-fill"></i></span>
                                                        <span className="count-box">19+</span>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-add-line"></i></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide as="li">
                                        <div className=" block-images position-relative">
                                            <div className="img-box">
                                                <img src={tvthrillers4} className="img-fluid" alt="" />
                                            </div>
                                            <div className="block-description">
                                                <h6 className="iq-title"><Link to="/show-details">Night Mare</Link></h6>
                                                <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                                    <div className="badge badge-secondary p-1 mr-2">18+</div>
                                                    <span className="text-white">3 Seasons</span>
                                                </div>
                                                <div className="hover-buttons">
                                                    <Link to="/show-details" role="button" className="btn btn-hover iq-button">
                                                        <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                                        Play Now
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="block-social-info">
                                                <ul className="list-inline p-0 m-0 music-play-lists">
                                                    <li className="share">
                                                        <span><i className="ri-share-fill"></i></span>
                                                        <div className="share-box">
                                                            <div className="d-flex align-items-center">
                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-heart-fill"></i></span>
                                                        <span className="count-box">19+</span>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-add-line"></i></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide as="li">
                                        <div className="block-images position-relative">
                                            <div className="img-box">
                                                <img src={tvthrillers5} className="img-fluid" alt="" />
                                            </div>
                                            <div className="block-description">
                                                <h6 className="iq-title"><Link to="/show-details">The Marshal King</Link></h6>
                                                <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                                    <div className="badge badge-secondary p-1 mr-2">17+</div>
                                                    <span className="text-white">1 Season</span>
                                                </div>
                                                <div className="hover-buttons">
                                                    <Link to="/show-details" role="button" className="btn btn-hover iq-button">
                                                        <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                                        Play Now
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="block-social-info">
                                                <ul className="list-inline p-0 m-0 music-play-lists">
                                                    <li className="share">
                                                        <span><i className="ri-share-fill"></i></span>
                                                        <div className="share-box">
                                                            <div className="d-flex align-items-center">
                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-heart-fill"></i></span>
                                                        <span className="count-box">19+</span>
                                                    </li>
                                                    <li>
                                                        <span><i className="ri-add-line"></i></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
