import { Autocomplete, Divider, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';
import styles from "../../../../views/backend/pages/auth/form.module.css";
import Chip from '@mui/material/Chip';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as HomeAction from '../../../../actions/HomeActions'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../../constant/routes';
let City = require('country-state-city').City;
let State = require('country-state-city').State;

export default function DigitalForm() {
    const user_details = JSON.parse(sessionStorage.getItem('user'))
    console.log("user_details", user_details)
    const [formData, setFormData] = useState({
        google_map_link: "",
        catalogue: "",
        firstname: user_details?.person_name,
        lastname: "",
        email: user_details?.email,
        mobile_number: user_details?.mobileNumber,
        resort_name: user_details?.resort_name
    })
    const [nextInfo, setNextInfo] = useState(false)
    const [images, setImages] = useState([])
    const [category, setCategory] = useState([])
    const dispatch = useDispatch()
    const { getAllCategories, submitDigitalForm } = bindActionCreators(HomeAction, dispatch)
    const { getAllData } = HomeAction
    const history = useHistory()
    const [selectedState, setSelectedState] = useState({})
    const [fileInputs, setFileInputs] = useState([{ id: 1, file: null }]);
    const [value, setValue] = React.useState([]);
    const [value1, setValue1] = React.useState([]);
    const [value2, setValue2] = React.useState([]);
    const [value3, setValue3] = React.useState([]);

    const [nextInfo1, setNextInfo1] = useState(false)
    const [allData, setAllData] = useState({})
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;



    const handleFileChange = (event, index) => {
        const newFileInputs = [...fileInputs];
        newFileInputs[index].file = event.target.files[0];
        setFileInputs(newFileInputs);
        setImages([...images, event.target.files[0]])
    };
    const addFileInput = () => {
        if (fileInputs.length == 10) {
            toast?.error("You can upload maximum 10 images")
        } else {
            setFileInputs([...fileInputs, { id: fileInputs.length + 1, file: null }]);

        }
    };

    useEffect(() => {
        (async () => {
            const resData = await getAllCategories()
            const res = await getAllData()
            console.log("res,res", res)
            // setOtherData(res)
            setAllData(res)
            // settags(res?.tags?.data)
            // setLanguage(res?.language?.data)
            setCategory(resData?.data)
        })();
    }, [])
    console.log(formData)



    const handleSubmit = async (e) => {
        e.preventDefault()
        // formData.images = images
        formData.image0 = images?.[0] || ""
        formData.image1 = images?.[1] || ""
        formData.image2 = images?.[2] || ""
        formData.image3 = images?.[3] || ""
        formData.image4 = images?.[4] || ""
        formData.image5 = images?.[5] || ""
        formData.image6 = images?.[6] || ""
        formData.image7 = images?.[7] || ""
        formData.image8 = images?.[8] || ""
        formData.image9 = images?.[9] || ""

        if (!formData?.resort_video_link) {
            toast?.error("Please upload video link")
        }
        else if (images?.length < 3) {
            toast.error("Please select atleast three images")
        }
        else {
            console.log("first", formData)
            formData.id = user_details?.id
            const formlist = new FormData()
            Object.keys(formData).map((key) => formlist.append(key, formData?.[key]));
            const resData = await submitDigitalForm(formlist)
            console.log(resData)
            if (resData?.statuscode == 200) {
                toast.success(resData?.message)
                setFormData({})
                setTimeout(() => {
                    history.push('/')
                }, 1500)
            }
            else {
                toast.error(resData?.message)
            }
        }

    }


    const handleNextInfo = () => {
        if (nextInfo == false) {
            if (!formData?.firstname) {
                toast.error("Please enter first name")
            }
            // else if (!formData?.lastname) {
            //     toast.error("Please enter last name")
            // }
            else if (!formData?.email) {
                toast.error("Please enter email")
            }
            else if (!regex.test(formData?.email)) {
                toast.error("Please enter valid email")
            }
            else if (!formData?.mobile_number) {
                toast.error("Please enter mobile number")
            }
            else if (!formData?.gender) {
                toast.error("Please select gender")
            }
            else if (!formData?.resort_name) {
                toast.error("Please enter resort name")
            }
            else if (!formData?.resort_address) {
                toast.error("Please enter resort address")
            }
            else if (!formData?.state) {
                toast.error("Please select state")
            }
            else if (!formData?.city) {
                toast.error("Please select city")
            }
            else if (!formData?.pincode) {
                toast.error("Please enter PIN Code")
            }
            else {
                setNextInfo(true)
            }
        }
        else {

            if (!formData.nearest_highway) {
                toast.error("Please enter nearest highway")
            }
            else if (!formData.nearest_bus_station) {
                toast.error("Please enter nearest bus station")
            }
            else if (!formData.nearest_railway_station) {
                toast.error("Please enter nearest railway station")
            }
            else if (!formData.resort_type) {
                toast.error("Please select resort type")
            }
            else if (!formData.food_type) {
                toast.error("Please select food type")
            }
            else if (!formData.booking_type) {
                toast.error("Please select booking type")
            }
            else if (!formData.facility_type) {
                toast.error("Please select facility type")
            }
            else if (!formData.smoking_zone) {
                toast.error("Please select smoking option")
            }
            else if (!formData.weekend_price) {
                toast.error("Please enter weekend price")
            }
            else if (!formData.holiday_price) {
                toast.error("Please enter holiday price")
            }
            else if (!formData.weekdays_price) {
                toast.error("Please enter week days price")
            }
            else if (!formData.group_price) {
                toast.error("Please enter group price")
            }
            else if (!formData.cancellation_rules) {
                toast.error("Please enter cancellation rules")
            }
            else {
                setNextInfo1(true)
                setNextInfo(false)
            }

        }
    }
    return (
        <section className="m-profile setting-wrapper">
            <Container style={{ border: "1px solid #404043", padding: "40px", borderRadius: "1rem" }}>
                <h5>Digital Form for Content Submission</h5>
                <form onSubmit={handleSubmit}>

                    <Row className='mt-3'>
                        {
                            !nextInfo && !nextInfo1 &&
                            <>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            value={formData?.firstname}
                                            onChange={(event) => (setFormData({ ...formData, firstname: event.target.value }))}
                                            placeholder="Full Name"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                {/* <Col md="4">
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail3"
                                            onChange={(event) => (setFormData({ ...formData, lastname: event.target.value }))}
                                            placeholder="Last Name"
                                            autoComplete="off"

                                            style={{ height: '55px' }} 
                                        />

                                    </Form.Group>
                                </Col> */}
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail3"
                                            value={formData?.email}
                                            onChange={(event) => (setFormData({ ...formData, email: event.target.value }))}
                                            placeholder="Email"
                                            disabled
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>

                                <Col md="4">
                                    <Form.Group>
                                        <PhoneInput
                                            labelId="demo-simple-select-label"
                                            country={"in"}
                                            disabled
                                            value={"91" + formData?.mobile_number}
                                            onChange={(phone, countryCode) => {
                                                setFormData({
                                                    ...formData,
                                                    mobile_number:
                                                        // form?.countryCode == "+" + countryCode.dialCode

                                                        (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                                                            : phone.substring(2)),
                                                    countryCode: "+" + countryCode.dialCode,
                                                });

                                            }}
                                            isValid={(value, country) => {
                                                if (country.dialCode == "91" && value[2] < 6) {
                                                    return 'Invalid Number'
                                                } else {
                                                    return true;
                                                }
                                            }}
                                            dropdownClass={styles.phoneInputDropDown}
                                            highlightClass={styles.highlight}
                                            buttonClass={styles.phoneInputButton}
                                            inputClass={styles.phoneInput}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="4">
                                    <Form.Group>
                                        <Form.Label style={{ color: "white" }}>Gender</Form.Label>
                                        <div key={`inline-radio`} className="mb-3">
                                            <Form.Check

                                                inline
                                                label="Male"
                                                name="gender"
                                                type='radio'
                                                id={`inline-radio-1`}
                                                onClick={(event) => (setFormData({ ...formData, gender: "Male" }))}
                                            />
                                            <Form.Check

                                                inline
                                                label="Female"
                                                name="gender"
                                                type='radio'
                                                id={`inline-radio-2`}
                                                onClick={(event) => (setFormData({ ...formData, gender: "Female" }))}

                                            />
                                            <Form.Check

                                                inline
                                                label="Not Disclose"
                                                name="gender"
                                                type='radio'
                                                id={`inline-radio-3`}
                                                onClick={(event) => (setFormData({ ...formData, gender: "not disclose" }))}

                                            />
                                        </div>

                                    </Form.Group>
                                </Col>


                                <Col md="4">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            value={formData?.resort_name}
                                            onChange={(event) => (setFormData({ ...formData, resort_name: event.target.value }))}
                                            placeholder="Resort Name"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>


                                <Col md="12" >
                                    <Form.Group style={{ width: "100%" }}>
                                        {/* <Form.Label style={{ display: "flex", color: "white" }}>Resort Full Address</Form.Label> */}

                                        <Form.Control
                                            as="textarea"
                                            name='description'
                                            placeholder='Resort Full Address'
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, resort_address: event.target.value }))}

                                        ></Form.Control>


                                    </Form.Group>
                                </Col>

                                <Col md="6">
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={State?.getStatesOfCountry('IN')}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                setSelectedState(value)
                                                setFormData({ ...formData, state: value?.name })
                                            }
                                        }}
                                        sx={{

                                            borderRadius: "1rem",

                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                        )}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Select State"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                }}
                                                InputLabelProps={{
                                                    ...params.InputLabelProps,
                                                    style: { color: 'white' }, // Change label color
                                                }}
                                            />}
                                    />

                                </Col>
                                <Col md="6">
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={City?.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                setSelectedState(value)
                                                setFormData({ ...formData, city: value?.name })

                                            }
                                        }}
                                        sx={{
                                            borderRadius: "1rem",
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                        )}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Select City"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                }}
                                                InputLabelProps={{
                                                    ...params.InputLabelProps,
                                                    style: { color: 'white' }, // Change label color
                                                }}
                                            />}
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mt-4"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, pincode: event.target.value }))}
                                            placeholder="PIN Code"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mt-4"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, google_map_link: event.target.value }))}
                                            placeholder="Google Direction Link (Optional)"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            // className="mt-4"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, catalogue: event.target.value }))}
                                            placeholder="Catalogue (Optional)"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="6"></Col>
                            </>
                        }

                        {
                            nextInfo &&
                            <>
                                <Col md="12">How to reach ?</Col>
                                <hr style={{ color: "grey", width: "100%" }} />
                                <Col md="4">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, nearest_highway: event.target.value }))}
                                            placeholder="Nearest Highway"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="4">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, nearest_bus_station: event.target.value }))}
                                            placeholder="Nearest Bus Station"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="4">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, nearest_railway_station: event.target.value }))}
                                            placeholder="Nearest Railway Station"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>

                                {/* 
                                <Col md="6">
                                    <Form.Group>
                                        <Autocomplete
                                            multiple
                                            id="fixed-tags-demo"

                                            value={value}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    setValue(newValue);
                                                    const selectedIds = newValue.map(option => option.id);
                                                    setFormData({ ...formData, resort_type: selectedIds });
                                                }
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                            )}
                                            options={allData?.resort_type_list}
                                            getOptionLabel={(option) => option?.resort_type_name}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option?.resort_type_name}
                                                        {...getTagProps({ index })}
                                                        sx={{
                                                            bgcolor: '#7c7c7e', // Change chip background color
                                                            color: 'black', // Change chip text color
                                                        }}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Resort Type"
                                                    placeholder="Resort Type "
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                    }}
                                                    InputLabelProps={{
                                                        ...params.InputLabelProps,
                                                        style: { color: 'white' }, // Change label color
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col> */}

                                <Col md="6">

                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={allData?.resort_type_list}
                                        getOptionLabel={(option) => option.resort_type_name}
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                setFormData({ ...formData, resort_type: value?.id });
                                            }
                                        }}
                                        sx={{
                                            borderRadius: "1rem",
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                        )}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Select Resort Type"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                }}
                                                InputLabelProps={{
                                                    ...params.InputLabelProps,
                                                    style: { color: 'white' }, // Change label color
                                                }}
                                            />}
                                    />

                                </Col>


                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                        <Autocomplete
                                            multiple
                                            id="fixed-tags-demo"

                                            value={value1}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    setValue1(newValue);
                                                    const selectedIds = newValue.map(option => option.id);
                                                    setFormData({ ...formData, food_type: selectedIds });
                                                }
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                            )}
                                            options={allData?.food_option_list}
                                            getOptionLabel={(option) => option?.food_type}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option?.food_type}
                                                        {...getTagProps({ index })}
                                                        sx={{
                                                            bgcolor: '#7c7c7e', // Change chip background color
                                                            color: 'black', // Change chip text color
                                                        }}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Food Options"
                                                    placeholder="Food Options "
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                    }}
                                                    InputLabelProps={{
                                                        ...params.InputLabelProps,
                                                        style: { color: 'white' }, // Change label color
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>

                                </Col>

                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                        <Autocomplete
                                            multiple
                                            id="fixed-tags-demo"

                                            value={value2}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    setValue2(newValue);
                                                    const selectedIds = newValue.map(option => option.id);
                                                    setFormData({ ...formData, booking_type: selectedIds });
                                                }
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                            )}
                                            options={allData?.booking_option_list}
                                            getOptionLabel={(option) => option?.booking_option}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option?.booking_option}
                                                        {...getTagProps({ index })}
                                                        sx={{
                                                            bgcolor: '#7c7c7e', // Change chip background color
                                                            color: 'black', // Change chip text color
                                                        }}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Booking Options"
                                                    placeholder="Booking Options "
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                    }}
                                                    InputLabelProps={{
                                                        ...params.InputLabelProps,
                                                        style: { color: 'white' }, // Change label color
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>

                                </Col>


                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>Last Name</Form.Label> */}
                                        <Autocomplete
                                            multiple
                                            id="fixed-tags-demo"

                                            value={value3}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    setValue3(newValue);
                                                    const selectedIds = newValue.map(option => option.id);
                                                    setFormData({ ...formData, facility_type: selectedIds });
                                                }
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ backgroundColor: 'black', color: "#ffffff" }}>{children}</Paper>
                                            )}
                                            options={allData?.facilities_list}
                                            getOptionLabel={(option) => option?.facility_type}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option?.facility_type}
                                                        {...getTagProps({ index })}
                                                        sx={{
                                                            bgcolor: '#7c7c7e', // Change chip background color
                                                            color: 'black', // Change chip text color
                                                        }}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Facilities"
                                                    placeholder="Facilities Options "
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: { borderColor: 'white', color: 'white' }, // Change border and value color
                                                    }}
                                                    InputLabelProps={{
                                                        ...params.InputLabelProps,
                                                        style: { color: 'white' }, // Change label color
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>

                                </Col>

                                <Col md="6">
                                    <Form.Group>
                                        <Form.Label style={{ color: "white" }}>Smoking Allowed</Form.Label>
                                        <div key={`inline-radio`} className="mb-1">
                                            <Form.Check

                                                inline
                                                label="Yes"
                                                name="gender"
                                                type='radio'
                                                id={`inline-radio-1`}
                                                onClick={(event) => (setFormData({ ...formData, smoking_zone: "yes" }))}
                                            />
                                            <Form.Check

                                                inline
                                                label="No"
                                                name="gender"
                                                type='radio'
                                                id={`inline-radio-2`}
                                                onClick={(event) => (setFormData({ ...formData, smoking_zone: "no" }))}

                                            />

                                        </div>

                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                </Col>

                                <div>Package and Pricing</div>
                                <hr style={{ color: "grey", width: "100%" }} />
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, weekend_price: event.target.value }))}
                                            placeholder="Week End"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, holiday_price: event.target.value }))}
                                            placeholder="Holiday"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, weekdays_price: event.target.value }))}
                                            placeholder="Monday to Friday"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, group_price: event.target.value }))}
                                            placeholder="Group (> 5/10/20)"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="12" >
                                    <Form.Group style={{ width: "100%" }}>
                                        {/* <Form.Label style={{ display: "flex", color: "white" }}>Resort Full Address</Form.Label> */}

                                        <Form.Control
                                            as="textarea"
                                            name='description'
                                            placeholder='Cancellation Rules'
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, cancellation_rules: event.target.value }))}

                                        ></Form.Control>


                                    </Form.Group>
                                </Col>
                            </>
                        }
                        {
                            nextInfo1 &&
                            <>
                                <Col md="6">
                                    <Form.Group>
                                        {/* <Form.Label style={{ color: "white" }}>First Name</Form.Label> */}
                                        <Form.Control
                                            type="text"
                                            className="mb-0"
                                            id="exampleInputEmail2"
                                            onChange={(event) => (setFormData({ ...formData, resort_video_link: event.target.value }))}
                                            placeholder="Resort's Video link"
                                            autoComplete="off"

                                            style={{ height: '55px' }} // Set the desired height

                                        />

                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <span style={{ fontSize: "10px", color: "red" }}>You can Upload your video file to Google Drive, VIMEO, WETRANSFER, or any other online platform. After you upload the video, a link will be generated, which you must copy and paste into the text box above.</span>

                                </Col>
                                <Col md="6" >

                                    {fileInputs.map((input, index) => (
                                        <Col md="12" style={{ display: "flex", justifyContent: "end" }}>
                                            <Form.Group controlId={`formFile${input.id}`} className="mb-3" key={input.id} style={{ display: "flex", justifyContent: "end" }}>
                                                <div id="file-upload-form" className="uploader">
                                                    <input
                                                        id={`file-upload${input.id}`}
                                                        type="file"
                                                        name={`fileUpload${input.id}`}
                                                        accept="image/*"
                                                        onChange={(event) => handleFileChange(event, index)}
                                                    />
                                                    <label htmlFor={`file-upload${input.id}`} id="file-drag">
                                                        <div id="start">
                                                            <div className='mt-3'>Upload resort images </div>
                                                            <span id={`file-upload-btn${input.id}`} className="btn btn-primary">Select Image</span><br />
                                                            <span>{input.file?.name ? (input.file?.name?.length <= 20 ? input.file?.name : input.file?.name?.substring(0, 20) + "...") : ""}</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    ))}


                                </Col>
                                <Col md="6" style={{ textAlign: "center" }}>
                                    <div className="btn btn-hover" onClick={addFileInput}>
                                        +
                                    </div>
                                </Col>
                            </>
                        }


                        <Button style={{ display: "block", margin: "0 auto", marginTop: "1rem" }} className="btn btn-hover" onClick={nextInfo1 ? handleSubmit : handleNextInfo}>{nextInfo1 ? "Submit" : "Next"}</Button>
                    </Row>
                </form>

            </Container>
        </section>
    )
}
